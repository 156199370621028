.contact-kld1901 {
    font-family: "Play", serif;
    .section-1-kld1901 {
        margin: 0 auto;
        display: flex;
        padding: 150px 0;
        .box-banner {
            width: 100%;
            padding: 0 125px 0 0;
            img {
                animation: fadeInSlide 0.5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 400px;
                object-fit: cover;
                z-index: 1;
                top: 150px;
                width: 92.5%;
                border-radius: 0 15px 15px 0;
            }
            .text-banner {
                background: linear-gradient(to right, #000000ce, #eeeeee23);
                height: 400px;
                justify-content: center;
                z-index: 2;
                position: relative;
                color: white;
                display: flex;
                flex-direction: column;
                .title {
                    width: 1280px;
                    margin: 0 auto;
                    font-size: 3rem;
                    text-transform: uppercase;
                    padding: 20px 0;
                    font-weight: bold;
                    letter-spacing: 2px;
                }
                .sub-title {
                    width: 1280px;
                    margin: 0 auto;
                    font-size: 16px;
                    .sp1 {
                        color: rgb(169, 169, 169);
                    }
                    .sp2 {
                        font-size: 20px;
                        color: white;
                    }
                }
            }
        }
    }
    .section-2-kld1901 {
        width: 1400px;
        margin: 0 auto;
        padding: 0 0 125px 0;
        .section-2-1-kld1901 {
            display: flex;
            .left {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .title {
                    font-size: 2rem;
                    letter-spacing: 1px;
                    font-weight: bold;
                }
                .bxt {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    .boxtc {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        i {
                            font-size: 24px;
                            color: #af8647;
                        }
                        .cont {
                            .abv {
                                color: #af8647;
                            }
                        }
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                gap: 20px;
                .title {
                    font-size: 2rem;
                    letter-spacing: 1px;
                    font-weight: bold;
                }
                .bxt {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    .boxtc {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        width: 100%;
                        textarea,
                        input {
                            width: 100%;
                            padding: 10px;
                            border-radius: 15px;
                            border: 1px solid grey;
                        }
                    }
                    .btn-sent {
                        width: 100%;
                        .btn-submit {
                            border-radius: 15px;
                            border: none;
                            padding: 10px 40px;
                            color: white;
                            text-transform: uppercase;
                            background-color: #af8647;
                            border: 1px solid #af8647;
                            transition: all 0.2s;
                            &:hover {
                                border: 1px solid #af8647;
                                color: #af8647;
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }
    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
