.homepage-kld1901 {
    font-family: "Play", serif;
    .section-1-kld1901 {
        width: 100vw;
        height: 100vh;
        transition: all 1.4s;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100%;
            width: 100%;
            color: black;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 150px;
            .title {
                width: 1000px;
                font-size: 2.5rem;
                letter-spacing: 1px;
                font-weight: 600;
                padding: 20px 0;
            }
            .sub-title {
                width: 1000px;
                font-size: 3rem;
                letter-spacing: 1px;
            }
            .btn-intro {
                padding: 20px 0;
                .btn-ele {
                    border-radius: 15px;
                    width: 200px;
                    background-color: #af8647;
                    color: white;
                    transition:
                        box-shadow 0.2s ease-in-out,
                        color 0.5s ease-in-out;
                    text-align: center;
                    padding: 10px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        color: #af8647;
                        box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                    }
                }
            }
        }
    }
    .section-2-kld1901 {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        padding: 100px 0;
        .left {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .title {
                font-weight: bold;
                font-size: 2rem;
                letter-spacing: 1px;
            }
            .text {
                font-size: 1.1rem;
                letter-spacing: 1px;
            }
            .btn-kld1901 {
                width: 200px;
                border-radius: 15px;
                background-color: #af8647;
                color: white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    color: #af8647;
                    box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            .box-img {
                object-fit: cover;
                box-shadow: 20px -20px 0em 0em #af8647;
                img {
                    width: 500px;
                    height: 500px;
                    object-fit: cover;
                }
            }
            .text {
                font-size: 1.1rem;
                width: 100%;
                text-align: center;
                padding: 0 10px 10px 10px;
                border-left: 1px solid black;
                border-right: 1px solid black;
                border-bottom: 1px solid black;
            }
        }
    }
    .section-3-kld1901 {
        margin: 0 auto;
        padding: 50px 0;
        position: relative;
        overflow: hidden;
        .background {
            background-color: #af8647;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 200px;
            z-index: 1;
        }
        .section-3-1-kld1901 {
            background-color: rgb(245, 245, 245);
            padding: 30px 50px;
            width: 1400px;
            position: relative;
            z-index: 2;
            margin: 0 auto;
            border-radius: 15px;
            .title {
                font-size: 2rem;
                letter-spacing: 1px;
                padding: 20px;
                text-align: center;
            }
            .content {
                display: flex;
                flex-wrap: wrap;
                .box {
                    padding: 20px;
                    display: flex;
                    .box-content {
                        border-radius: 15px;
                        background-color: white;
                        padding: 30px;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        img {
                            width: 75px;
                        }
                        .sub-title {
                            font-size: 1.5rem;
                            letter-spacing: 1px;
                        }
                        .text {
                            letter-spacing: 1px;
                        }
                        .btn-section {
                            cursor: pointer;
                            position: relative;
                            text-align: end;
                            width: max-content;
                            overflow: hidden;
                            font-size: 1.1rem;
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                width: 100%;
                                background-color: black;
                                transition: width 0.4s ease;
                            }

                            &:hover {
                                color: #af8647;
                                transition: all 0.2s ease;
                                &::after {
                                    width: 0%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .section-4-kld1901 {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 50px 0;

        .title {
            text-align: center;
            font-size: 2rem;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }

        .section-4-1-kld1901 {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .box {
                flex: 1 1 calc(33.333% - 20px);
                max-width: calc(33.333% - 20px);
                background-color: #f9f9f9;
                border: 1px solid #ddd;
                border-radius: 8px;
                overflow: hidden;
                transition:
                    transform 0.3s ease,
                    box-shadow 0.3s ease;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
                }

                img {
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }

                .content {
                    padding: 30px;

                    .title {
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-bottom: 10px;
                        color: #333;
                        text-align: start;
                        text-transform: uppercase;
                    }

                    .time {
                        font-size: 0.9rem;
                        color: #888;
                        margin-bottom: 10px;
                    }

                    .text {
                        font-size: 1rem;
                        color: #555;
                        margin-bottom: 15px;
                    }

                    .btn-kld1901 {
                        cursor: pointer;
                        position: relative;
                        text-align: end;
                        width: max-content;
                        overflow: hidden;
                        font-size: 1.1rem;
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 2px;
                            width: 100%;
                            background-color: black;
                            transition: width 0.4s ease;
                        }

                        &:hover {
                            color: #af8647;
                            transition: all 0.2s ease;
                            &::after {
                                width: 0%;
                            }
                        }
                    }
                }
            }
        }
    }

    .section-5-kld1901 {
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }
    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
    .fade-out {
        opacity: 0;
        width: 0;
        height: 0;
        visibility: hidden; /* Ẩn nội dung hoàn toàn sau khi hiệu ứng chạy xong */
    }
}
@media (max-width: 950px) {
    .homepage-kld1901 {
        .section-1-kld1901 {
            width: 100%;
            height: 400px !important;
            video {
                height: 400px;
            }
            .introduce {
                height: 400px;
                padding: 60px 20px;
                justify-content: flex-end;
                .title {
                    width: 100%;
                    font-size: 1.7rem;
                }
                .sub-title {
                    width: 100%;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                .btn-intro {
                    .btn-ele {
                        width: 150px;
                        font-size: 13px;
                        border: 1px solid white;
                    }
                }
            }
        }
        .section-2-kld1901 {
            width: 100%;
        }
        .section-3-kld1901 {
            width: 100%;
        }
        .section-4-kld1901 {
            width: 100%;
        }
        .section-5-kld1901 {
            width: 100%;
        }
    }
}
