.category-landing-page {
    color: white;
    .banner-image {
        margin: 0;
        padding: 150px;
        align-items: center;
        img {
            animation: bounce 2s infinite ease-in-out;
            width: 100%;
            background-size: cover; /* Đảm bảo ảnh vừa khít khung */
            background-position: center; /* Căn giữa ảnh */
            background-repeat: no-repeat; /* Không lặp ảnh */
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        @keyframes bounce {
            0%,
            100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-20px);
            }
        }
        .content {
            .text {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                font-size: 5rem;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .tel {
                font-size: 16px;
                color: white;
                text-decoration: none;
                padding: 10px;
                border: 1px solid #50adff;
                border-radius: 15px;
                transition:
                    box-shadow 0.5s ease-in-out,
                    color 0.5s ease-in-out;
                &:hover {
                    box-shadow: inset 0 0 1.5em 1.5em #50adff;
                    color: white !important;
                }
            }
        }
    }

    .part-product {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title {
            margin: 0 auto;
            text-align: center;
            color: grey;
            font-size: 18px;
        }
        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
        }
        .list-product {
            // margin: 0;
            // .nav-link {
            //     text-align: center;
            //     padding: 15px;
            //     display: flex;
            //     justify-content: center;
            //     flex-direction: column;
            //     .product {
            //         border: 1px solid #50adff;
            //         // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            //         border-radius: 15px;
            //         overflow: hidden;
            //         background-color: #212121ac;
            //         color: white;
            //         .image-container {
            //             width: 100%;
            //             height: 400px;
            //             overflow: hidden;
            //             position: relative;

            //             img {
            //                 width: 100%;
            //                 position: relative;
            //                 top: 0;
            //                 transition: top 5s ease; /* Hiệu ứng mượt khi hover */
            //             }
            //             &:hover img {
            //                 top: -100%; /* CSS sẽ xử lý giá trị từ React */
            //             }
            //         }
            //         .product-name {
            //             text-transform: uppercase;
            //             font-size: 18px;
            //             padding: 15px 5px;
            //             font-weight: bold;
            //         }
            //         .product-view {
            //             text-transform: uppercase;
            //             padding: 0 5px 15px 5px;
            //         }
            //         .product-price {
            //             padding: 15px 0;
            //             border-top: 1px solid #50adff;
            //             color: white;
            //             font-size: 20px;
            //             transition:
            //                 box-shadow 0.5s ease-in-out,
            //                 color 0.5s ease-in-out;
            //         }
            //         &:hover {
            //             .product-price {
            //                 box-shadow: inset 0 0 1.5em 1.5em #50adff;
            //                 color: white !important;
            //             }
            //         }
            //     }
            // }
            .nav-link {
                text-align: center;
                display:flex;
                flex-direction: column;
                align-items: center;
                padding: 50px;
                transition: all 0.2s ease-in-out;
                .image-container {
                    img {
                        width: 100%;
                        filter: drop-shadow(rgb(255, 255, 255) 0px 0px 0.55rem);
                    }
                }
                .product-name {
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    padding: 10px 5px;
                    font-weight: bold;
                }
                .product-price {
                    padding: 10px 0;
                    color: #50adff;
                    font-size: 1.3rem;
                    transition:
                        box-shadow 0.5s ease-in-out,
                        color 0.5s ease-in-out;
                }
                .btn-show {
                    cursor: pointer;
                    position: relative;
                    width: max-content;
                    overflow: hidden;
                    padding: 10px 0;
                    font-size: 1rem;
                    letter-spacing: 1px;
                    
                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: #50adff;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        transition: all 0.2s ease;
                        color: #50adff;

                        &::after {
                            width: 0%;
                        }
                    }
                }
                &:hover {
                    transition: all 0.2s ease-in-out;
                    transform: scale(1.1);
                }
            }
        }
    }
    .introduce-landing-page {
        border-radius: 15px;
        background: rgba(10, 10, 10, 0.643);
        margin: 30px 150px;
        padding: 30px;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        .text {
            max-width: 1200px;
            margin: 0 auto;
            .title {
                letter-spacing: 2px;
                color: white;
                font-weight: bold;
                font-style: italic;
                font-size: 2rem;
                text-transform: uppercase;
            }
            .content {
                letter-spacing: 1px;
                color: white;
                width: 600px;
                font-size: 18px;
            }
        }
        .image-intro {
            img {
                animation: bounce 2s infinite ease-in-out;
                width: 100%;
                background-size: cover; /* Đảm bảo ảnh vừa khít khung */
                background-position: center; /* Căn giữa ảnh */
                background-repeat: no-repeat; /* Không lặp ảnh */
            }
            @keyframes bounce {
                0%,
                100% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-20px);
                }
            }
        }
    }
    .type-website {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .content {
            display: flex;
            justify-content: center;

            ul {
                li {
                    cursor: pointer;

                    padding: 5px 0;
                    a {
                        text-decoration: none;
                        color: white;
                        &:hover {
                            color: #3a87cb;
                        }
                    }
                    .active {
                        color: #50adff;
                    }
                }
            }
        }
    }
    .animate-kassdev-bottom-5s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-bottom-4s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-bottom-3s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-bottom-2s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-bottom-1s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-top-5s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-top-2s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-top-4s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-top-3s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-top-1s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 1s ease;
    }
    .animate-kassdev-right-5s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-right-4s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-right-3s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-right-2s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-right-1s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-left-5s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-left-3s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-left-4s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-left-2s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-left-1s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 1s ease;
    }

    .animate-kassdev-show {
        opacity: 1;
        transform: translateY(0);
        transform: translateX(0);
    }
}
@media (max-width: 950px) {
    .category-landing-page {
        .banner-image {
            padding: 150px 10px 100px 10px;
            .image {
                text-align: center;
                img {
                    width: 350px;
                    height: 250px;
                }
            }
            .content {
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                .text {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    font-size: 2rem;
                    gap: 10px;
                }
            }
        }
        .part-product {
            width: 100%;
            padding: 20px;
            .title {
                font-size: 1.4rem;
            }
        }
        .introduce-landing-page {
            margin: 0;
            padding: 30px 10px;
            width: 100%;
            .text {
                order: 1;
                .title {
                    font-size: 1.3rem;
                }
                .content {
                    width: 100%;
                    font-size: 1rem;
                }
            }
        }
        .type-website .content {
            justify-content: normal;
            ul {
                margin: 0;
            }
        }
        .animate-kassdev-right-1s,
        .animate-kassdev-left-1s,
        .animate-kassdev-bottom-1s,
        .animate-kassdev-top-1s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 1s ease;
        }
        .animate-kassdev-right-3s,
        .animate-kassdev-left-3s,
        .animate-kassdev-bottom-3s,
        .animate-kassdev-top-3s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 3s ease;
        }
        .animate-kassdev-right-4s,
        .animate-kassdev-left-4s,
        .animate-kassdev-bottom-4s,
        .animate-kassdev-top-4s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 4s ease;
        }
        .animate-kassdev-right-5s,
        .animate-kassdev-left-5s,
        .animate-kassdev-bottom-5s,
        .animate-kassdev-top-5s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 5s ease;
        }
        .animate-kassdev-right-2s,
        .animate-kassdev-left-2s,
        .animate-kassdev-bottom-2s,
        .animate-kassdev-top-2s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 2s ease;
        }
        .animate-kassdev-show {
            opacity: 1;
            transform: translateX(0) !important;
        }
    }
}
