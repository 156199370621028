.shiny-text {
    color: #0087fde9; /* Adjust this color to change intensity/style */
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 40%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 60%);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    display: inline-block;
    animation: shine 2s linear infinite;
    
}

@keyframes shine {
    0% {
        background-position: 100%;
    }
    100% {
        background-position: -100%;
    }
}

.shiny-text.disabled {
    animation: none;
}
