.sell-website-process {
    color: white;
    .title-process-step {
        padding: 150px 0 50px 0;
        width: 1200px;
        margin: 0 auto;
        .sub-title {
            margin: 0 auto;
            text-align: center;
            color: grey;
            font-size: 18px;
        }
        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .sell-website-process-work {
        padding: 50px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        .step-process {
            padding: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            cursor: pointer;
            &:hover {
                .title {
                    background: #50adff;
                }
            }
            &:not(:last-child):after {
                content: "";
                position: absolute;
                top: 5%;
                right: -25px;
                width: 50px;
                height: 2px;
                background-color: #50adff;
                transform: translateY(-50%);
            }
            &:not(:last-child):before {
                content: "";
                position: absolute;
                top: 5%;
                right: -40px;
                border: solid transparent;
                border-left-color: #50adff;
                border-width: 10px;
                transform: translateY(-50%);
            }
            .title {
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: 2px;
                border-radius: 50%;
                border: 1px solid #50adff;
                width: max-content;
                padding: 10px 15px;
                position: relative;
            }
            .sub-title {
                color: #50adff;
                padding: 10px 0;
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 1px;
            }
            .box-content{
                padding:10px;
                display:flex;
                flex-direction: column;
                text-align: start;
                .sub-title{
                    color: #50adff;
                    padding:15px 0;
                    font-size: 1.2rem;
                }
                .text{
                    padding:10px 0;
                    font-size: 1rem;
                }
            }
        }
    }
    .accordion-box {
        width: 100%;
        .accordion {
            padding: 50px 0;
            width: 1200px;
            margin: 0 auto;
            background-color: unset !important;
            color: white;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            .accordion-item {
                margin-bottom: 20px;
                background-color: unset !important;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                .accordion-header {
                    .accordion-button {
                        color: white;
                        font-size: 1.5rem;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        box-shadow: none;
                        background-color: unset !important;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                        &:after {
                            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
                            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
                        }
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        margin-bottom: 20px;
                        color: white;
                        padding: 30px 20px;
                        border-radius: 15px;
                        background: rgba(10, 10, 10, 0.5);
                        .box-text {
                            padding: 10px 0;
                            font-size: 1.1rem;
                            letter-spacing: 1px;
                            .title {
                                color: #50adff;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
    .type-website {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .content {
            display: flex;
            justify-content: center;

            ul {
                li {
                    cursor: pointer;

                    padding: 5px 0;
                    a {
                        text-decoration: none;
                        color: white;
                        &:hover {
                            color: #3a87cb;
                        }
                    }
                    .active {
                        color: #50adff;
                    }
                }
            }
        }
    }
    .animate-kassdev-bottom-5s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-bottom-4s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-bottom-3s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-bottom-2s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-bottom-1s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-top-5s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-top-2s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-top-4s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-top-3s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-top-1s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 1s ease;
    }
    .animate-kassdev-right-5s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-right-4s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-right-3s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-right-2s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-right-1s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-left-5s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-left-3s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-left-4s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-left-2s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-left-1s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 1s ease;
    }

    .animate-kassdev-show {
        opacity: 1;
        transform: translateY(0);
        transform: translateX(0);
    }
}
@media (max-width: 950px) {
    .sell-website-process {
        .title-process-step {
            width: 100%;
            padding: 100px 20px;
        }
        .sell-website-process-work{
            flex-direction: column;
            width: 100%;
            padding:0 20px;
            gap:100px;
            .step-process {
                width: 100%;
                &:nth-child(1):after {
                    top: 105%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(1):before {
                    top: 111%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(2):after {
                    top: 105%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(2):before {
                    top: 112%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(3):after {
                    top: 105%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(3):before {
                    top: 115%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(4):after {
                    top: 105%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(4):before {
                    top: 110%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(5):after {
                    top: 105%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:nth-child(5):before {
                    top: 109%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                .title {
                    font-size: 2rem;
                    font-weight: bold;
                    letter-spacing: 2px;
                    border-radius: 50%;
                    border: 1px solid #50adff;
                    width: max-content;
                    padding: 10px 15px;
                    position: relative;
                }
                .sub-title {
                    color: #50adff;
                    padding: 10px 0;
                    font-size: 1.5rem;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
        .accordion-box {
            width: 100%;
            .accordion {
                width: 100%;
                .accordion-item {
                    .accordion-header {
                        .accordion-button {
                        }
                    }
                }
            }
        }

        .type-website .content {
            justify-content: normal;
            ul {
                margin: 0;
            }
        }
        .animate-kassdev-right-1s,
        .animate-kassdev-left-1s,
        .animate-kassdev-bottom-1s,
        .animate-kassdev-top-1s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 1s ease;
        }
        .animate-kassdev-right-3s,
        .animate-kassdev-left-3s,
        .animate-kassdev-bottom-3s,
        .animate-kassdev-top-3s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 3s ease;
        }
        .animate-kassdev-right-4s,
        .animate-kassdev-left-4s,
        .animate-kassdev-bottom-4s,
        .animate-kassdev-top-4s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 4s ease;
        }
        .animate-kassdev-right-5s,
        .animate-kassdev-left-5s,
        .animate-kassdev-bottom-5s,
        .animate-kassdev-top-5s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 5s ease;
        }
        .animate-kassdev-right-2s,
        .animate-kassdev-left-2s,
        .animate-kassdev-bottom-2s,
        .animate-kassdev-top-2s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 2s ease;
        }
        .animate-kassdev-show {
            opacity: 1;
            transform: translateX(0) !important;
        }
    }
}
