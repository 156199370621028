.first-st-kld2112 {
    font-family: "Montserrat", serif;
    background-color: black;
    color: white;
    
    .section-1-kld2112 {
        width: 100vw;
        height: 100vh;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.398);
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 150px;
            .title {
                font-family: "Playfair Display", serif !important;
                width: 1300px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 4rem;
                letter-spacing: 2px;
            }
            .sub-title {
                font-family: "Playfair Display", serif;
                width: 1300px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 1.5rem;
                letter-spacing: 1px;
                padding: 20px 0;
            }
        }
        .btn-intro {
            height: 100vh;
            width: 100%;
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: end;
            padding: 20px 150px;
            .btn-ele {
                background-color: white;
                color: #af8647;
                border-radius: 50%;
                transition:
                    background-color 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 20px 15px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    color: white;
                    background-color: #af8647;
                }
            }
        }
    }
    .section-2-kld2112 {
        width: 900px;
        margin: 0 auto;
        height: 200px;
        text-align: center;
        display: flex;
        align-items: end;
        justify-content: center;
        text-transform: uppercase;
        border-left: 1px solid rgb(48, 48, 48);
        border-right: 1px solid rgb(48, 48, 48);
    }
    .section-3-kld2112 {
        text-align: center;
        width: 900px;
        margin: 0 auto;
        padding: 50px 0;
        font-weight: bold;
        font-size: 3rem;
        text-transform: uppercase;
    }
    .section-4-kld2112 {
        width: 900px;
        margin: 0 auto;
        border-left: 1px solid rgb(48, 48, 48);
        border-right: 1px solid rgb(48, 48, 48);
        padding: 50px;
        display: flex;
        flex-direction: column;
        .text {
            display: flex;
            padding: 10px 0;
        }
        .show-less-more {
            max-height: 0;
            overflow: hidden;
            transition: max-height 1s;
            &.active-show-less-more {
                max-height: 3500px;
            }
        }
        .btn-show-less-more {
            position: relative;
            padding: 10px 0;
            width: max-content;
            display: flex;
            justify-content: space-between;
            gap: 30px;
            align-items: center;
            cursor: pointer;
            &::after {
                background-color: #bc8f45;
                bottom: 0;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                transition: 0.4s;
                width: 100%;
            }
            &:hover {
                &::after {
                    width: 0;
                }
            }
        }
    }
    .section-5-kld2112 {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px;
        .col-md-4 {
            padding: 50px 0 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            .title {
                font-size: 4rem;
                span {
                    font-size: 1rem;
                }
            }
            .text {
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }
    .section-7-kld2112 {
        .btn-next {
            position: absolute;
            top: 50%;
            left: 130px;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            font-size: 24px;
            color: black;
            padding: 10px 20px;
            border-radius: 50%;
            border: 1px solid #af8647;
            transition: all 0.2s;
            &:hover {
                background-color: #af8647;
                i {
                    color: white !important;
                }
            }
        }
        .btn-prev {
            position: absolute;
            top: 50%;
            right: 130px;
            transform: translateY(-50%);
            z-index: 1;
            cursor: pointer;
            font-size: 24px;
            color: black;
            padding: 10px 20px;
            border-radius: 50%;
            border: 1px solid #af8647;
            transition: all 0.2s;
            &:hover {
                background-color: #af8647;
                i {
                    color: white !important;
                }
            }
        }
        .slick-dots {
            position: relative;
            color: white;
            display: flex;
            width: 200px;
            margin: 0 auto;
            align-items: center;
            gap: 30px;
        }
        .slick-track {
            height: 600px;
            .slick-slide {
                img {
                    height: 600px;
                    transition: height 0.5s ease;
                }
                &:not(.slick-active) {
                    padding: 100px 200px;
                    img {
                        border-radius: 15px;
                        height: 400px;
                    }
                }
                &.slick-active {
                    padding: 0;
                    img {
                        border-radius: 15px;
                        height: 600px;
                    }
                }
                transition: padding 0.5s ease;
            }
        }
    }
    .section-8-kld2112 {
        // padding: 120px 0 100px 0;
        // display: flex;
        // .left {
        //     img {
        //         width: 100%;
        //         height: 100%;
        //         object-fit: cover;
        //     }
        // }
        // .right {
        //     background-color: rgb(231, 231, 227);
        //     color: black;
        //     padding: 30px 0;
        //     .content-right {
        //         width: 500px;
        //         margin: 0 auto;
        //         display: flex;
        //         flex-direction: column;
        //         gap: 20px;
        //         .title {
        //             font-size: 2.5rem;
        //             font-weight: bold;
        //             text-transform: uppercase;
        //             letter-spacing: 1px;
        //         }
        //         .form-group {
        //             display: flex;
        //             justify-content: space-between;
        //             .select-div {
        //                 width: 100%;
        //                 position: relative;
        //                 display: inline-block;
        //                 .custom-select {
        //                     border-radius: 15px;
        //                     width: 100%;
        //                     padding: 20px;
        //                     background-color: unset;
        //                     color: black;
        //                     font-weight: bold;
        //                     border: 1px solid grey;

        //                     cursor: pointer;
        //                     letter-spacing: 1px;
        //                     &:focus {
        //                         outline: none;
        //                         box-shadow: none;
        //                     }
        //                     option {
        //                         display: none;
        //                     }
        //                 }
        //                 .dropdown-select {
        //                     visibility: hidden;
        //                     text-align: start;
        //                     max-height: 0;
        //                     width: 100%;
        //                     border: 1px solid grey;
        //                     transition:
        //                         opacity 0.3s ease,
        //                         visibility 0.3s ease,
        //                         max-height 0.3s ease;
        //                     position: absolute;
        //                     background-color: rgb(231, 231, 227);
        //                     z-index: 1;
        //                     border-top: none;
        //                     border-left: 1px solid grey;
        //                     border-right: 1px solid grey;
        //                     border-bottom: 1px solid grey;
        //                     border-radius: 15px;
        //                     .option-select {
        //                         padding: 10px 20px;
        //                         opacity: 0;
        //                         cursor: pointer;
        //                         transition: all 0.2s;

        //                         &:hover {
        //                             background-color: white;
        //                             color: #af8647;
        //                         }
        //                     }
        //                 }
        //                 .open-select {
        //                     opacity: 1;
        //                     visibility: visible;
        //                     max-height: 500px;
        //                     border-top: none;
        //                     border-left: 1px solid grey;
        //                     border-right: 1px solid grey;
        //                     border-bottom: 1px solid grey;
        //                     .option-select {
        //                         opacity: 1;
        //                     }
        //                 }
        //             }
        //         }
        //         .button-group {
        //             display: flex;
        //             justify-content: space-between;

        //             .button-div {
        //                 width: 100%;
        //                 position: relative;
        //                 display: inline-block;
        //                 &:first-child {
        //                     .custom-button {
        //                         border-radius: 15px 0 0 15px;
        //                     }
        //                 }
        //                 &:last-child {
        //                     .custom-button {
        //                         border-radius: 0 15px 15px 0;
        //                     }
        //                 }
        //                 .custom-button {
        //                     box-sizing: border-box;
        //                     width: 100%;
        //                     padding: 20px;
        //                     background-color: unset;
        //                     color: black;
        //                     font-weight: bold;
        //                     border-width: 1px;
        //                     border-style: solid;
        //                     cursor: pointer;
        //                     letter-spacing: 1px;
        //                     transition: all 0.2s;

        //                     &:focus {
        //                         outline: none;
        //                         box-shadow: none;
        //                     }

        //                     &:hover {
        //                         border: 1px solid grey;

        //                         background-color: #bc8f45 !important;
        //                         color: white !important;
        //                     }
        //                 }
        //             }
        //         }
        //         .btn-prev-next {
        //             transition: all 0.2s;
        //             &:hover {
        //                 background-color: #af8647;
        //                 .fa-chevron-left,
        //                 .fa-chevron-right {
        //                     color: white !important;
        //                 }
        //             }
        //         }
        //         .date-meeting {
        //             padding: 20px 10px;
        //             .box {
        //                 text-align: center;
        //                 padding: 20px;
        //                 border: 1px solid #ccc;
        //                 border-radius: 8px;
        //                 cursor: pointer;
        //                 .sub-title {
        //                     font-size: 15px;
        //                     color: #888;
        //                 }
        //                 .title {
        //                     font-size: 2rem;
        //                     font-weight: bold;
        //                     color: #333;
        //                     padding: 10px 0;
        //                 }
        //                 &:hover {
        //                     border-color: #bc8f45 !important;
        //                 }
        //             }
        //         }
        //     }
        // }
        padding: 120px 0;
        width: 100vw;
        .section-8-1-image-kld2112 {
            position: absolute;
            display: flex;
            z-index: 1;
            width: 100vw;
            .section-8-1-color-kld2112 {
                width: 30%;
                height: 800px;
                background-color: rgb(135 117 78);
            }
            img {
                animation: fadeInSlide 0.2s ease-in-out;
                width: 70%;
                height: 800px;
                object-fit: cover;
            }
        }
        .introduce {
            height: 800px;
            width: 1300px;
            margin: 0 auto;
            color: white;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .introduce-1 {
                width: 800px;
                color: black;
                background-color: rgb(231, 231, 227);
                padding: 30px 0;
                .content-right {
                    width: 500px;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    input {
                        background: unset;
                        padding: 10px 20px;
                        border: none;
                        font-weight: bold;
                        letter-spacing: 1px;
                        border-bottom: 1px solid #af8647;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                    .title {
                        font-size: 2.5rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                    .form-group {
                        display: flex;
                        justify-content: space-between;
                        .select-div {
                            width: 100%;
                            position: relative;
                            display: inline-block;
                            .custom-select {
                                border-radius: 15px;
                                width: 100%;
                                padding: 20px;
                                background-color: unset;
                                color: black;
                                font-weight: bold;
                                border: 1px solid grey;

                                cursor: pointer;
                                letter-spacing: 1px;
                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                                option {
                                    display: none;
                                }
                            }
                            .dropdown-select {
                                visibility: hidden;
                                text-align: start;
                                max-height: 0;
                                width: 100%;
                                border: 1px solid grey;
                                transition:
                                    opacity 0.3s ease,
                                    visibility 0.3s ease,
                                    max-height 0.3s ease;
                                position: absolute;
                                background-color: rgb(231, 231, 227);
                                z-index: 1;
                                border-top: none;
                                border-left: 1px solid grey;
                                border-right: 1px solid grey;
                                border-bottom: 1px solid grey;
                                border-radius: 15px;
                                .option-select {
                                    padding: 10px 20px;
                                    opacity: 0;
                                    cursor: pointer;
                                    transition: all 0.2s;

                                    &:hover {
                                        background-color: white;
                                        color: #af8647;
                                    }
                                }
                            }
                            .open-select {
                                opacity: 1;
                                visibility: visible;
                                max-height: 500px;
                                border-top: none;
                                border-left: 1px solid grey;
                                border-right: 1px solid grey;
                                border-bottom: 1px solid grey;
                                .option-select {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .button-group {
                        display: flex;
                        justify-content: space-between;

                        .button-div {
                            width: 100%;
                            position: relative;
                            display: inline-block;
                            &:first-child {
                                .custom-button {
                                    border-radius: 15px 0 0 15px;
                                }
                            }
                            &:last-child {
                                .custom-button {
                                    border-radius: 0 15px 15px 0;
                                }
                            }
                            .custom-button {
                                box-sizing: border-box;
                                width: 100%;
                                padding: 20px;
                                background-color: unset;
                                color: black;
                                font-weight: bold;
                                border-width: 1px;
                                border-style: solid;
                                cursor: pointer;
                                letter-spacing: 1px;
                                transition: all 0.2s;

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }

                                &:hover {
                                    border: 1px solid grey;

                                    background-color: #bc8f45 !important;
                                    color: white !important;
                                }
                            }
                        }
                    }
                    .btn-next {
                        position: absolute;
                        top: 50%;
                        right: -75px;

                        transform: translateY(-50%);
                        z-index: 1;
                        cursor: pointer;
                        font-size: 24px;
                        color: black;
                        padding: 10px 20px;
                        border-radius: 50%;
                        border: 1px solid #af8647;
                        transition: all 0.2s;
                        &:hover {
                            background-color: #af8647;
                            i {
                                color: white !important;
                            }
                        }
                    }
                    .btn-prev {
                        position: absolute;
                        top: 50%;
                        left: -75px;
                        transform: translateY(-50%);
                        z-index: 1;
                        cursor: pointer;
                        font-size: 24px;
                        color: black;
                        padding: 10px 20px;
                        border-radius: 50%;
                        border: 1px solid #af8647;
                        transition: all 0.2s;
                        &:hover {
                            background-color: #af8647;
                            i {
                                color: white !important;
                            }
                        }
                    }
                    .date-meeting {
                        padding: 20px 10px;
                        .box {
                            text-align: center;
                            padding: 20px;
                            border: 1px solid #ccc;
                            border-radius: 8px;
                            cursor: pointer;
                            .sub-title {
                                font-size: 15px;
                                color: #888;
                            }
                            .title {
                                font-size: 2rem;
                                font-weight: bold;
                                color: #333;
                                padding: 10px 0;
                            }
                            &:hover {
                                border-color: #bc8f45 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .section-9-kld2112 {
        width: 1300px;
        margin: 0 auto;
        .accordion-flush {
            .accordion-item {
                border: none;
                color: white;
                padding: 10px 0;
                background-color: unset;
                .accordion-header {
                    .accordion-button {
                        border-radius: 15px;
                        display: flex;

                        .e1st {
                            font-size: 16px;
                        }
                        .e2nd {
                            padding-left: 280px;
                            padding-right: 100px;
                            font-size: 3rem;
                        }
                        &:after {
                            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
                            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
                        }
                        padding: 60px 30px;
                        color: white;
                        font-size: 1.3rem;
                        background-color: rgba(246, 244, 239, 0.092);
                    }
                    .accordion-button:not(.collapsed) {
                        box-shadow: none;
                        border-radius: 15px 15px 0 0;
                    }
                }
                .accordion-collapse {
                    padding: 0 15px;
                    background-color: rgba(246, 244, 239, 0.092);
                    border-radius: 0 0 15px 15px;
                    .accordion-body {
                        display: flex;
                        padding: 0 100px 0 315px;

                        .left {
                            border-top: 1px solid #ccc;
                            width: 50%;
                            font-size: 1.5rem;
                            padding: 50px 0;
                        }
                        .right {
                            border-top: 1px solid #ccc;
                            width: 50%;
                            font-size: 1.1rem;
                            padding: 50px 0;
                        }
                    }
                }
            }
        }
    }
    .section-10-kld2112 {
        width: 1300px;
        margin: 0 auto;
        padding: 100px 0;
        img {
            height: 700px;
            border-radius: 15px;
            width: 100%;
            object-fit: cover;
        }
    }
    .section-6-kld2112 {
        .section-6-1-kld2112 {
            width: 1300px;
            max-height: 900px;
            margin: 0 auto;
            padding: 100px 0 50px 0;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            .left {
                width: 58%;
                .title {
                    font-size: 3rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                .sub-title {
                    letter-spacing: 1px;
                    font-size: 16px;
                    padding: 20px 0;
                }
                .box-input {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .input-cacul {
                        width: 48%;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding-bottom: 20px;
                        input {
                            font-weight: bold;
                            letter-spacing: 1px;
                            border: none;
                            background-color: unset;
                            padding: 20px;
                            color: white;
                            border-bottom: 1px solid grey;
                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                    .form-group {
                        display: flex;
                        justify-content: space-between;
                        .select-div {
                            width: 100%;
                            position: relative;
                            display: inline-block;
                            .custom-select {
                                width: 100%;
                                padding: 20px;
                                background-color: unset;
                                color: white;
                                font-weight: bold;
                                border: none;
                                border-bottom: 1px solid grey;
                                cursor: pointer;
                                letter-spacing: 1px;
                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                                option {
                                    display: none;
                                }
                            }
                            .dropdown-select {
                                visibility: hidden;
                                text-align: start;
                                max-height: 0;
                                width: 100%;
                                border: 1px solid grey;
                                transition:
                                    opacity 0.3s ease,
                                    visibility 0.3s ease,
                                    max-height 0.3s ease;
                                position: absolute;
                                background-color: black;
                                z-index: 1;
                                border-top: none;
                                border-left: 1px solid grey;
                                border-right: 1px solid grey;
                                border-bottom: 1px solid grey;
                                border-radius: 15px;
                                .option-select {
                                    padding: 10px 20px;
                                    opacity: 0;
                                    cursor: pointer;
                                    transition: all 0.2s;

                                    &:hover {
                                        background-color: white;
                                        color: #af8647;
                                    }
                                }
                            }
                            .open-select {
                                opacity: 1;
                                visibility: visible;
                                max-height: 500px;
                                border-top: none;
                                border-left: 1px solid grey;
                                border-right: 1px solid grey;
                                border-bottom: 1px solid grey;
                                .option-select {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .right {
                position: relative;
                width: 38%;
                height: auto;
                background: rgb(23 23 23);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px;
                border-radius: 15px;
                background-image: url(https://res.cloudinary.com/dolydpat4/image/upload/v1735192875/-1457898965983807999_yqnhc7.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                &::before {
                    content: ""; /* Cần có để pseudo-element hiển thị */
                    position: absolute; /* Đặt vị trí tuyệt đối */
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5); /* Màu đen với độ mờ 50% (0.5) */
                    z-index: 1; /* Đảm bảo lớp phủ nằm trên hình nền */
                }
                .title {
                    position: relative;
                    z-index: 2;
                    font-size: 3rem;
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 3px;
                }
                .sub-title {
                    position: relative;
                    z-index: 2;
                    font-size: 1.2rem;
                }
                .box-percent {
                    position: relative;
                    z-index: 2;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    justify-content: space-between;
                    width: 100%;
                    .cost-percent {
                        text-align: start;
                        display: flex;
                        justify-content: space-between;
                        .cost-percent-left {
                            border-bottom: 1px solid #eb9d29;
                            padding: 5px 0;
                        }
                        .cost-percent-right {
                            letter-spacing: 2px;
                            font-weight: bold;
                            padding: 10px 0 10px 0;
                        }
                    }
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .first-st-kld2112 {
        .section-1-kld2112 {
            width: 100%;
            height: 400px !important;
            img {
                height: 400px;
            }
            .introduce {
                height: 400px;
                padding: 60px 20px;
                .title {
                    width: 100%;
                    font-size: 1.7rem;
                }
                .sub-title {
                    width: 100%;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                .btn-intro {
                    .btn-ele {
                        width: 150px;
                        font-size: 13px;
                        border: 1px solid white;
                    }
                }
            }
            .btn-intro {
                height: 400px;
                .btn-ele {
                    font-size: 13px;
                }
            }
        }
        .section-2-kld2112 {
            width: 100%;
            padding: 20px;
        }
        .section-3-kld2112 {
            width: 100%;
            padding: 20px;
            font-size: 1.5rem;
        }
        .section-4-kld2112 {
            width: 100%;
            padding: 20px;
        }
        .section-5-kld2112 {
            width: 100%;
            padding: 20px;
            justify-content: start;
            .col-md-4 {
                width: 50%;
                align-items: start;
                .title {
                    font-size: 3rem;
                }
            }
        }
        .section-7-kld2112 {
            .btn-next,
            .btn-prev {
                font-size: 20px;
                padding: 8px 16px;
            }

            .btn-next {
                left: 20px;
            }

            .btn-prev {
                right: 20px;
            }

            .slick-track {
                height: 250px;

                .slick-slide {
                    img {
                        height: 250px;
                    }

                    &:not(.slick-active) {
                        padding: 50px 100px;
                        img {
                            height: 250px;
                        }
                    }

                    &.slick-active {
                        img {
                            height: 250px;
                        }
                    }
                }
            }
        }
        .section-8-kld2112 {
            width: 100%;
            height: 100%;
            .section-8-1-image-kld2112 {
                height: 300px;
                position: relative;
                .section-8-1-color-kld2112 {
                    width: 0;
                }
                img {
                    width: 100%;
                    height: 300px;
                }
            }
            .introduce {
                width: 100%;
                height: max-content;
                position: unset;
                .introduce-1 {
                    padding: 50px 20px;
                    width: 100%;

                    .content-right {
                        .form-group {
                            .custom-select {
                            }
                            .open-select {
                                z-index: 10 !important;
                            }
                        }
                        width: 100%;
                        .title {
                            font-size: 1.5rem;
                            text-transform: uppercase;
                        }
                        .date-meeting {
                            padding: 20px 60px;
                        }
                        .btn-next,
                        .btn-prev {
                            font-size: 20px;
                            padding: 8px 16px;
                        }

                        .btn-next {
                            right: 0px;
                        }

                        .btn-prev {
                            left: 0px;
                        }
                    }
                }
            }
        }
        .section-9-kld2112 {
            width: 100%;
            padding: 20px;
            .accordion-flush {
                .accordion-item {
                    .accordion-header {
                        .accordion-button {
                            .e2nd {
                                padding-left: 40px;
                                padding-right: 100px;
                                font-size: 1.5rem;
                            }
                        }
                    }
                    .accordion-collapse {
                        .accordion-body {
                            padding: 0 0 0 0px;
                            .left{
                                font-size: 1rem;
                            }
                            .right{
                                padding-left:10px;
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .section-10-kld2112 {
            width: 100%;
            padding: 20px;
            img {
                height: 300px;
            }
        }
        .section-6-kld2112{
            width: 100%;
            padding:100px 0;
            .section-6-1-kld2112{
                flex-direction: column;
                width: 100%;
                padding:20px;
                .left{
                    .title{
                        font-size: 1.5rem;
                    }
                    .sub-title{
                        font-size: 14px;
                    }
                    width: 100%;
                    .box-input{
                    
                    }
                }
                .right{
                    width: 100%;
                }
            }
        }
    }
}
