.section-ref-show {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: "Play", sans-serif;
    letter-spacing: 1px;
    .section-1, .section-2 {
        border-radius: 15px;
        background-color: #2e2e2e97;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 400px;
        height: auto;
        align-items: center;
        justify-content: center;
        .title {
            font-size: 1.5rem;
        }
        input {
            width: 100%;
            padding: 10px;
            color: white;
            background: none;
            box-shadow: none;
            border: 1px solid rgb(95, 95, 95);
            border-radius: 11px;
            text-align: center;
            letter-spacing: 1px;
        }
        .btn-ref {
            cursor: pointer;
            position: relative;
            text-align: end;
            width: max-content;
            overflow: hidden;
            font-size: 1.1rem;
            margin-bottom:20px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: #50adff;
                transition: width 0.4s ease;
            }

            &:hover {
                color: #50adff;
                transition: all 0.2s ease;
                &::after {
                    width: 0%;
                }
            }
        }
        .text{
            cursor: pointer;
            color:white;
            text-decoration: none;
        }
        .text-sub {
            text-decoration: underline;
            font-size: 13px;
            cursor: pointer;
        }
    }
}
