// @-webkit-keyframes snowflakes-fall {
//     0% {
//         top: -10%;
//     }
//     100% {
//         top: 100%;
//     }
// }
// @-webkit-keyframes snowflakes-shake {
//     0%,
//     100% {
//         -webkit-transform: translateX(0);
//         transform: translateX(0);
//     }
//     50% {
//         -webkit-transform: translateX(80px);
//         transform: translateX(80px);
//     }
// }
// @keyframes snowflakes-fall {
//     0% {
//         top: -10%;
//     }
//     100% {
//         top: 100%;
//     }
// }
// @keyframes snowflakes-shake {
//     0%,
//     100% {
//         transform: translateX(0);
//     }
//     50% {
//         transform: translateX(80px);
//     }
// }
// .snowflake {
//     color: #fff;
//     font-size: 1em;
//     font-family: Arial, sans-serif;
//     text-shadow: 0 0 5px #000;
//     position: fixed;
//     top: -10%;
//     z-index: 9999;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     cursor: default;
//     -webkit-animation-name: snowflakes-fall, snowflakes-shake;
//     -webkit-animation-duration: 10s, 3s;
//     -webkit-animation-timing-function: linear, ease-in-out;
//     -webkit-animation-iteration-count: infinite, infinite;
//     -webkit-animation-play-state: running, running;
//     animation-name: snowflakes-fall, snowflakes-shake;
//     animation-duration: 10s, 3s;
//     animation-timing-function: linear, ease-in-out;
//     animation-iteration-count: infinite, infinite;
//     animation-play-state: running, running;
// }
// .snowflake:nth-of-type(0) {
//     left: 1%;
//     -webkit-animation-delay: 0s, 0s;
//     animation-delay: 0s, 0s;
// }
// .snowflake:nth-of-type(1) {
//     left: 10%;
//     -webkit-animation-delay: 1s, 1s;
//     animation-delay: 1s, 1s;
// }
// .snowflake:nth-of-type(2) {
//     left: 20%;
//     -webkit-animation-delay: 6s, 0.5s;
//     animation-delay: 6s, 0.5s;
// }
// .snowflake:nth-of-type(3) {
//     left: 30%;
//     -webkit-animation-delay: 4s, 2s;
//     animation-delay: 4s, 2s;
// }
// .snowflake:nth-of-type(4) {
//     left: 40%;
//     -webkit-animation-delay: 2s, 2s;
//     animation-delay: 2s, 2s;
// }
// .snowflake:nth-of-type(5) {
//     left: 50%;
//     -webkit-animation-delay: 8s, 3s;
//     animation-delay: 8s, 3s;
// }
// .snowflake:nth-of-type(6) {
//     left: 60%;
//     -webkit-animation-delay: 6s, 2s;
//     animation-delay: 6s, 2s;
// }
// .snowflake:nth-of-type(7) {
//     left: 70%;
//     -webkit-animation-delay: 2.5s, 1s;
//     animation-delay: 2.5s, 1s;
// }
// .snowflake:nth-of-type(8) {
//     left: 80%;
//     -webkit-animation-delay: 1s, 0s;
//     animation-delay: 1s, 0s;
// }
// .snowflake:nth-of-type(9) {
//     left: 90%;
//     -webkit-animation-delay: 3s, 1.5s;
//     animation-delay: 3s, 1.5s;
// }
// .snowflake:nth-of-type(10) {
//     left: 25%;
//     -webkit-animation-delay: 2s, 0s;
//     animation-delay: 2s, 0s;
// }
// .snowflake:nth-of-type(11) {
//     left: 65%;
//     -webkit-animation-delay: 4s, 2.5s;
//     animation-delay: 4s, 2.5s;
// }

.homepage-v1-kassdev {
    width: 1300px;
    margin: 0 auto;
    .section-sell-website-banner {
        padding: 150px 0 0 0;
        margin: 0 0 150px 0;
        .section-sell-website-banner-box {
            display: flex;
            flex-direction: column;
            height: inherit;
            justify-content: center;
            .section-sell-website-banner-title {
                color: white;
                font-size: 1.5rem;
            }
            .section-sell-website-banner-subtitle {
                color: #50adff;
                font-size: 3rem;
                display: flex;
                gap: 20px;
                align-items: center;
                .sub {
                    border-radius: 15px;
                    color: white;
                    background-color: #50adff;
                    padding: 10px 20px;
                }
            }
            .section-sell-website-banner-content {
                color: white;
                font-size: 1.3rem;
            }
            .section-sell-website-banner-list {
                display: flex;
                flex-wrap: wrap;
                color: white;
                gap: 15px;
                align-items: center;
                font-size: 1.2rem;
                > div {
                    width: 45%;
                }
            }
        }
        .section-sell-website-banner-anime3d {
            display: flex;
            justify-content: flex-end;
            img {
                animation: bounce 2s infinite ease-in-out;
                width: 500px;
                height: 700px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            @keyframes bounce {
                0%,
                100% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-20px);
                }
            }
        }
    }
    .section-sell-website-service {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 30px 0;
        color: white;
        align-items: center;
        gap: 20px;
        margin-bottom: 150px;
        .title {
            text-transform: uppercase;
            font-size: 2rem;
        }
        .content {
            width: 100%;
            flex-wrap: wrap;
            .part-service {
                padding: 20px 12px;
                align-items: stretch;
                display: flex;
                .single-part {
                    &:hover {
                        .corner {
                            border-radius: 15px;
                            transition: all 1s;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    position: relative;
                    .corner {
                        transition: all 1s;
                        position: absolute;
                        width: 60px;
                        height: 30px;
                        border: 3px solid #50adff;
                        border-radius: 3px;
                        transition: none;
                    }

                    .top-left {
                        border-radius: 15px 0 0 0;
                        top: 0;
                        left: 0;
                        border-right: none;
                        border-bottom: none;
                    }

                    .top-right {
                        border-radius: 0 15px 0 0;
                        top: 0;
                        right: 0;
                        border-left: none;
                        border-bottom: none;
                    }

                    .bottom-left {
                        border-radius: 0 0 0 15px;
                        bottom: 0;
                        left: 0;
                        border-right: none;
                        border-top: none;
                    }

                    .bottom-right {
                        border-radius: 0 0 15px 0;
                        bottom: 0;
                        right: 0;
                        border-left: none;
                        border-top: none;
                    }
                    border-radius: 15px;

                    padding: 30px 20px;
                    display: flex;
                    gap: 20px;
                    background: rgba(10, 10, 10, 0.513);
                    .left {
                        font-size: 1.5rem;
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .above {
                            font-size: 1.5rem;
                            text-transform: uppercase;
                        }
                        .down {
                            color: grey;
                        }
                    }
                }
            }
        }
    }
    .section-sell-website-price {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 30px 0;
        margin: 120px 0;
        color: white;
        align-items: center;
        gap: 20px;
        .title {
            text-transform: uppercase;
            font-size: 2rem;
            text-align: center;
        }
        .list-price {
            width: 100%;
            .content {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding: 10px 30px;
                .price {
                    border-radius: 15px;
                    // border: 1px solid #50adff;
                    width: 100%;
                    position: relative;
                    .price-above {
                        border-radius: 15px;
                        background: rgba(10, 10, 10, 0.681);
                        padding: 30px;
                        .title-price {
                            text-transform: uppercase;
                            font-size: 1.5rem;
                        }
                        .sub-title-price {
                            color: #50adff;
                        }
                    }
                    .price-down {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        padding: 30px;
                        border-radius: 0 0 15px 15px;
                        background-color: rgba(38, 38, 38, 0.744);
                        a {
                            text-decoration: none;
                            color: #50adff;
                            padding: 10px;
                            border: 1px solid #50adff;
                            border-radius: 10px;
                            text-align: center;
                            background-color: black;
                            transition:
                                box-shadow 0.5s ease-in-out,
                                color 0.5s ease-in-out;
                            &:hover {
                                box-shadow: inset 0 0 1.5em 1.5em #50adff;
                                .shiny-text {
                                    transition: color 0.5s ease-in-out;
                                    color: white !important;
                                }
                            }
                        }
                    }
                    &:hover {
                        .corner {
                            border-radius: 15px;
                            transition: all 1s;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    transition: all 1s;
                    .corner {
                        position: absolute;
                        width: 60px;
                        height: 90px;
                        border: 3px solid #50adff;
                        border-radius: 3px;
                        transition: none;
                    }

                    .top-left {
                        border-radius: 15px 0 0 0;
                        top: 0;
                        left: 0;
                        border-right: none;
                        border-bottom: none;
                    }

                    .top-right {
                        border-radius: 0 15px 0 0;
                        top: 0;
                        right: 0;
                        border-left: none;
                        border-bottom: none;
                    }

                    .bottom-left {
                        border-radius: 0 0 0 15px;
                        bottom: 0;
                        left: 0;
                        border-right: none;
                        border-top: none;
                    }

                    .bottom-right {
                        border-radius: 0 0 15px 0;
                        bottom: 0;
                        right: 0;
                        border-left: none;
                        border-top: none;
                    }
                }
            }
        }
    }
    .section-sell-website-blog {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 30px 0;
        color: white;
        align-items: center;
        gap: 20px;
        .title {
            text-transform: uppercase;
            font-size: 2rem;
            text-align: center;
        }
        .list-blog {
            width: 100%;
            .content {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding: 10px 30px;
                text-decoration: none;
                .part-blog {
                    img {
                        width: 100%;
                        height: 200px;
                        border-radius: 15px;
                    }
                    .time {
                        color: grey;
                        font-size: 16px;
                        padding: 10px 0;
                    }
                    .title {
                        font-size: 1.2rem;
                        text-align: left;
                        color: white;
                    }
                }
            }
        }
        .btn-more-blog {
            cursor: pointer;
            position: relative;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1rem;
            letter-spacing: 1px;
            text-decoration: none;
            color: white;
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: bold;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: #50adff;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: #50adff;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .section-sell-website-slide {
        .wrapper {
            width: 100%;
            height: 100%;
            // background-size: cover;
            position: relative;
            animation: fadeInSlide 0.5s ease-in-out !important;
        }

        .selectors {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            background: rgba(0, 0, 0, 0.1);
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 3;

            .selector {
                border: 1px solid #50adff;
                background: none;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin: 5px 0;
                opacity: 0.6;
                cursor: pointer;
                transition:
                    background 0.3s ease-in-out,
                    opacity 0.3s ease-in-out;

                &.active,
                &:hover {
                    background: #50adff;
                    opacity: 0.9;
                }
            }
        }

        .panel {
            width: 40vw;
            height: 500px;
            color: #fff;
            transition: all 500ms ease-in-out;
            display: none;
            flex-direction: column;

            .panel-video {
                animation: fadeInSlide 0.5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &.active {
                top: 0;
                left: 0;
                display: flex;
                z-index: 2;
                position: revert;
                .panel-header {
                    background-color: rgba(0, 0, 0, 0.24);
                    z-index: 2;
                    margin: 0;
                    padding: 1.3rem 3.5rem;
                    p {
                        animation:
                            moveUp 1s ease-in-out,
                            fadeInOpacity 1s ease-in-out;
                    }
                }
                .panel-info {
                    background-color: rgba(0, 0, 0, 0.24);
                    z-index: 2;
                    margin: 0;
                    padding: 1.3rem 3.5rem;
                    p {
                        animation:
                            moveUp 2s ease-in-out,
                            fadeInOpacity 2s ease-in-out;
                    }
                }

                .panel-header {
                    padding-top: 2em;
                    font-weight: normal;
                    font-size: 2.5rem;
                    text-transform: capitalize;
                }

                .panel-info {
                    font-size: 1.2rem;
                    line-height: 1.8em;
                    opacity: 1;
                }
                .panel-button {
                    background-color: rgba(0, 0, 0, 0.24);
                    height: 100%;
                    // width: 100%;
                    z-index: 2;

                    color: #fff;
                    cursor: pointer;
                    font-size: 0.8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    p {
                        animation:
                            moveUp 3s ease-in-out,
                            fadeInOpacity 3s ease-in-out;
                        a {
                            border-radius: 15px;
                            margin: 0.5rem 0.5rem 1.3rem 3.5rem;
                            padding: 1em 1.3em;
                            background: #50adff;
                            transition:
                                box-shadow 0.5s ease-in-out,
                                color 0.5s ease-in-out;

                            &:hover {
                                box-shadow: inset 0 0 1.5em 1.5em #fff;
                                color: black !important;
                            }

                            &:focus {
                                outline: none;
                                // color: #000;
                            }
                        }
                    }
                }
            }
        }

        @keyframes moveUp {
            from {
                transform: translateY(40px);
            }
            to {
                transform: translateY(0);
            }
        }

        @keyframes fadeInOpacity {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    .section-sell-website-question {
        align-items: center;
        margin: 0 auto;
        margin: 50px 0;
        padding: 30px 150px;
        color: white;
        // border: 1px solid rgb(29, 29, 29);
        border-radius: 30px;
        // background: rgba(10, 10, 10, 0.643);
        display: flex;
        flex-direction: column;
        align-items: center;
        .section-sell-website-question-above {
            .section-sell-website-question-title {
                text-transform: uppercase;
                text-align: center;
                font-size: 2rem;
                font-style: italic;
                &::after {
                    content: "";
                    display: table;
                    clear: both;
                    width: 100%;
                    height: 50px;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: bottom;
                    padding-top: 0;
                }
            }
            .section-sell-website-question-subtitle {
                text-align: center;
                padding: 10px;
            }
        }
        .section-sell-website-question-below {
            width: 100%;
            justify-content: center;
            .section-sell-website-question-below-left {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 30px;
                .section-sell-website-question-below-left-box {
                    // display: flex;
                    // gap: 20px;

                    .section-sell-website-question-below-left-img {
                        img {
                            border-radius: 15px;
                        }
                    }
                    .section-sell-website-question-below-left-content {
                        .section-sell-website-question-below-left-title {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            font-size: 1.1rem;
                            letter-spacing: 1px;
                            font-weight: bold;
                        }
                    }
                }
            }
            .info {
                margin-top: 20px;
                padding: 15px 10px;
                text-align: center;
                border: 1px solid #50adff;
                border-radius: 15px;
                display: flex;
                justify-content: space-around;
                background: black;
                .gmail {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .content {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        .first {
                            font-size: 13px;
                            color: grey;
                        }
                    }
                    .icon {
                        background-color: #50adff;
                        width: max-content;
                        padding: 10px;
                        border-radius: 50%;
                    }
                }
            }
        }
        .section-sell-website-price {
            display: flex;
            justify-content: center;
            .section-sell-website-price-box {
                cursor: pointer;
                width: 30%;
                border: 0.5px solid #070707a1;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                margin: 20px;
                .section-sell-website-price-above {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 15px;
                    background: #070707a1;
                    color: white;
                    padding: 10px;
                    .section-sell-website-price-above-title {
                        font-size: 2rem;
                        text-transform: uppercase;
                    }
                    .section-sell-website-price-above-button {
                        font-size: 1rem;
                        text-transform: uppercase;
                    }
                }
                .section-sell-website-price-below {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    padding: 10px;
                    .section-sell-website-price-below-title {
                        font-size: 1.5rem;
                        text-transform: uppercase;
                        text-align: center;
                    }
                    .section-sell-website-price-below-subtitle {
                        padding: 10px 0;
                        font-size: 1rem;
                    }
                }
            }
        }
        .section-sell-website-description {
        }
    }
    .section-sell-website-library {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title {
            margin: 0 auto;
            text-align: center;
            color: grey;
            font-size: 18px;
        }
        .title {
            color: white;
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
            text-transform: uppercase;
        }
        .list-product {
            .nav-link {
                color: white;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 50px;
                transition: all 0.2s ease-in-out;
                .image-container {
                    img {
                        width: 100%;
                        filter: drop-shadow(rgb(255, 255, 255) 0px 0px 0.55rem);
                    }
                }
                .product-name {
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    padding: 10px 5px;
                    font-weight: bold;
                }
                .product-price {
                    padding: 10px 0;
                    color: #50adff;
                    font-size: 1.3rem;
                    transition:
                        box-shadow 0.5s ease-in-out,
                        color 0.5s ease-in-out;
                }
                .btn-show {
                    cursor: pointer;
                    position: relative;
                    width: max-content;
                    overflow: hidden;
                    padding: 10px 0;
                    font-size: 1rem;
                    letter-spacing: 1px;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: #50adff;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        transition: all 0.2s ease;
                        color: #50adff;

                        &::after {
                            width: 0%;
                        }
                    }
                }
                &:hover {
                    transition: all 0.2s ease-in-out;
                    transform: scale(1.1);
                }
            }
        }
    }

    .animate-kassdev-bottom-5s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-bottom-4s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-bottom-3s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-bottom-2s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-bottom-1s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-top-5s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-top-2s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-top-4s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-top-3s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-top-1s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 1s ease;
    }
    .animate-kassdev-right-5s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-right-4s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-right-3s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-right-2s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-right-1s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-left-5s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-left-3s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-left-4s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-left-2s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-left-1s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 1s ease;
    }

    .animate-kassdev-show {
        opacity: 1;
        transform: translateY(0);
        transform: translateX(0);
    }
}
@media (max-width: 950px) {
    .homepage-v1-kassdev {
        width: 100%;
        margin: 0;
        .section-sell-website-banner {
            padding: 100px 10px 0 10px;
            width: 100%;
            overflow: hidden;
            margin: 0;
            .section-sell-website-banner-box {
                order: 1;
                margin-bottom: 150px;
                .section-sell-website-banner-title {
                    font-size: 1.3rem;
                }
                .section-sell-website-banner-subtitle {
                    font-size: 1.6rem;
                }
                .section-sell-website-banner-content {
                    font-size: 1.1rem;
                }
                .section-sell-website-banner-list {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.1rem;
                    div {
                        width: 100%;
                    }
                }
            }
            .section-sell-website-banner-anime3d {
                justify-content: center;
                .tilted-card-figure {
                    width: 200px !important;
                    height: 300px !important;
                    .tilted-card-inner {
                        width: 200px !important;
                        height: 300px !important;
                        img {
                            width: 200px !important;
                            height: 300px !important;
                        }
                    }
                }
            }
        }
        .custom-class{
            margin:0 20px;
            .section-sell-website-question {
                border-radius: 0;
                padding: 30px 10px;
                width: 100%;
                overflow: hidden;
                .section-sell-website-question-above {
                    .section-sell-website-question-title {
                        font-size: 1.3rem;
                    }
                }
                .section-sell-website-question-below {
                    .section-sell-website-question-below-left {
                        padding: 0 10px 0 10px;
                        margin-bottom: 20px;
                        .section-sell-website-question-below-left-box {
                            .section-sell-website-question-below-left-content {
                                .section-sell-website-question-below-left-title {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    .info {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        padding: 20px 30px;
                        width: max-content;
                        // align-items: center;
                    }
                }
            }
        }
        .section-sell-website-service {
            padding: 30px 10px;
            text-align: center;
            width: 100%;
            overflow: hidden;
            .title {
                flex-direction: column;
                font-size: 1.1rem;
            }
            .content {
                gap: 20px;
            }
        }
        .section-sell-website-slide {
            .panel {
                height: 300px;
                width: 100%;
                // height: 80vh;
                &.active {
                    display: flex;

                    .panel-header,
                    .panel-info {
                        margin: 0;
                        padding: 20px 3.5rem !important;
                        animation:
                            moveUp 0.5s ease-in-out,
                            fadeInOpacity 0.5s ease-in-out;
                    }

                    .panel-header {
                        font-size: 1.6rem !important;
                    }

                    .panel-info {
                        font-size: 0.8rem !important;
                        // line-height: 1.8em;
                        opacity: 1;
                    }
                    .panel-button {
                        a {
                            margin: 0 !important;
                        }
                        padding: 1.3rem 0.5rem 1.3rem 3.5rem !important;
                    }
                }
            }
        }
        .section-sell-website-price {
            padding: 30px 10px;
            width: 100%;
            overflow: hidden;
            .title {
                font-size: 1.1rem;
            }
            .list-price {
                gap: 20px;
            }
        }
        .section-sell-website-library {
            width: 100%;
            padding: 20px;
            .title {
                flex-direction: column;
                font-size: 1.4rem;
            }
            .list-product{
                > div{
                    padding: 0 !important;
                    height: 500px !important;
                    .bit-infinite-menu{
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .face-title.active{
                            display: none;
                        }
                        .face-description{
                            .abv{
                                font-size: 0.9rem;
                            }
                            .blw{
                                span{
                                    font-size: 0.9rem !important;
                                }
                            }
                        }
                        .face-description.active {
                            opacity: 1;
                            position: static;
                            transform: translate(0%, -595%);
                            pointer-events: auto;
                            transition: 0.5s ease;
                        }
                        .action-button{
                            left: 0;
                            width: 100%;
                            bottom: 7.8em;
                            
                            &::after{
                                width: 0;
                            }
                            .action-button-icon{
                                width: max-content;
                                margin: 0 auto;
                                font-size: 1rem;
                                top:10px;
                                padding: 0 0 15px 0;
                                border-bottom: 1px solid #50adff;
                            }
                            // position: static;
                        }
                    }
                }
            }
        }
        .section-sell-website-blog {
            padding: 30px 10px;
            width: 100%;
            overflow: hidden;
            .title {
                font-size: 1.1rem;
            }
            .list-blog {
                gap: 20px;
            }
        }
    }

    .animate-kassdev-right-1s,
    .animate-kassdev-left-1s,
    .animate-kassdev-bottom-1s,
    .animate-kassdev-top-1s {
        opacity: 0;
        transform: translateX(-50px) !important;
        transition: all 1s ease;
    }
    .animate-kassdev-right-3s,
    .animate-kassdev-left-3s,
    .animate-kassdev-bottom-3s,
    .animate-kassdev-top-3s {
        opacity: 0;
        transform: translateX(-50px) !important;
        transition: all 3s ease;
    }
    .animate-kassdev-right-4s,
    .animate-kassdev-left-4s,
    .animate-kassdev-bottom-4s,
    .animate-kassdev-top-4s {
        opacity: 0;
        transform: translateX(-50px) !important;
        transition: all 4s ease;
    }
    .animate-kassdev-right-5s,
    .animate-kassdev-left-5s,
    .animate-kassdev-bottom-5s,
    .animate-kassdev-top-5s {
        opacity: 0;
        transform: translateX(-50px) !important;
        transition: all 5s ease;
    }
    .animate-kassdev-right-2s,
    .animate-kassdev-left-2s,
    .animate-kassdev-bottom-2s,
    .animate-kassdev-top-2s {
        opacity: 0;
        transform: translateX(-50px) !important;
        transition: all 2s ease;
    }
    .animate-kassdev-show {
        opacity: 1;
        transform: translateX(0) !important;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeInSlide {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
