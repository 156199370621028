.header-kld2812 {
    font-family: "Roboto", serif;
    position: fixed;
    z-index: 10;
    width: 100vw;
    animation: statingHeader 1s forwards;

    @keyframes statingHeader {
        from {
            transform: translateY(-100px);
        }
        to {
            transform: translateY(0);
        }
    }

    .desktop-kld2812 {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        z-index: 11;
        mix-blend-mode: exclusion;
        .section-1,
        .section-2 {
            font-size: 1.1rem;
            font-weight: bold;
            letter-spacing: 3px;
            cursor: pointer;
            text-transform: uppercase;
            span {
                display: inline-block;
                transform: translateY(0px);
                transition: transform 0.3s ease;
            }
        }

        .section-1:hover span {
            transform: translateY(-10px);
        }
    }

    .nav-link {
        display: block;
        position: fixed;
        width: 100%;
        z-index: 10;
        height: max-content;
        background-color: rgb(243 243 243);
        transform: translateX(100vh);
        color: black;
        transition: transform 0.5s ease;
        z-index: 12;
        .list-kld2812 {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 24px 20px;

            .section-1,
            .section-2 {
                font-size: 1.1rem;
                font-weight: bold;
                letter-spacing: 3px;
                cursor: pointer;
                text-transform: uppercase;
                span {
                    display: inline-block;
                    transform: translateY(0px);
                    transition: transform 0.3s ease;
                }
            }

            .section-1:hover span {
                transform: translateY(-10px);
            }
        }
        .section-3 {
            height: 93vh;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            .nav-item {
                color:black;
                text-decoration: none;
                cursor: pointer;
                font-size: 1.5rem;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 4px;
                img {
                    width: 200px;
                    height: 300px;
                }
            }
        }
    }
    .menu-show {
        transform: translateY(-8vh);
    }

    .menu-hide {
        transform: translateY(-110vh);
    }
}
@media (max-width: 950px) {
    .header-kld2812{
        width: 100%;
    }
}