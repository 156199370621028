.website-analysis {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-family: "Play", sans-serif;
    padding: 20px;
    #chartTouches,
    #chartSource,
    #chartAccess {
        width: 100%;
        height: 400px;
        font-size: 12px;
    }
    .col-md-6 {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title-chart {
            padding:20px 0;
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            letter-spacing: 1px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .title {
            .pre-button,
            .next-button,
            .text {
                font-size: 1.5rem;
                letter-spacing: 1px;
                font-weight: bold;
                text-transform: uppercase;
            }
            .pre-button,
            .next-button {
                border-radius: 50%;
                padding: 10px 20px;
                transition: all 0.2s ease;
            }

            align-items: center;
            padding: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    .total-analysis {
        color: white;
        padding: 20px;
        border-radius: 15px;
        background: linear-gradient(172.89deg, #5769df 24.12%, #5fcff9 102.64%);
        .data-analysis {
            .above {
                font-size: 1rem;
                font-weight: bold;
                text-align: center;
            }
            .under {
                font-size: 2rem;
                text-align: center;
                font-weight: bold;
                letter-spacing: 2px;
            }
        }
    }
}
@media (max-width: 950px) {
    .website-analysis {
        width: 100%;
        .col-md-6 {
            width: 100%;
            .title {
                .text {
                    font-size: 1rem;
                }
                .pre-button,
                .next-button {
                    padding: 0;
                }
            }
        }
    }
}
