.contact-kld2812 {
    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .contact-kld2812 {
        .section-11-kld2812 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                font-size: 2rem;
            }
            .box-sent {
                .text {
                    font-size: 1rem;
                }
                .input-text {
                    font-size: 1rem;
                }
            }
            .btn-sent {
                font-size: 1.5rem;
            }
        }
    }
}
