.footer-kld1812 {
    width: 100%;
    background-color: #ebc491;
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;

    letter-spacing: 2px;
    .section-1-kld1812 {
        padding: 20px 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 200px;
        }
        .contact {
            .title {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: bold;
                font-style: italic;
                padding: 10px 0;
            }
            .sub-title {
                font-size: 1rem;
                font-weight: 600;
                div {
                    cursor: pointer;
                    &:hover {
                        color: #ca8126;
                    }
                }
            }
        }
    }
    .section-2-kld1812{
        .section-2-1{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            padding:20px;
            .col-md-4{
                text-align: center;
                padding:20px 0;
                img{
                    width: 200px;
                }
                .title{
                    font-size: 1.1rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                    color:black;
                    padding: 10px;
                }
            }
            .sub-title{
                text-decoration: none;
                font-size: 1.1rem;
                color:#ca8126;
            }
        }
    }
}
@media (max-width: 950px) {
    .footer-kld1812 {
        .section-1-kld1812 {
            width: 100%;
            flex-direction: column;
            .contact {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .sub-title {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 5px 0;
                    div {
                        width: 100%;
                        cursor: pointer;
                        &:hover {
                            color: #ca8126;
                        }
                    }
                }
            }
        }
        hr {
            width: 90% !important;
        }
        .section-2-kld1812{
            width: 100%;
            .section-2-1{
                flex-direction: column;
                width: 100%;
            }
        }
    }
}
