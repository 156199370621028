.photo-book-kld2812 {
    .section-1-kld2812 {
        width: 100vw;
        height: 100vh;
        transition: all 1.4s;
        video {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            z-index: 6;
            mix-blend-mode: exclusion;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            height: 95vh;
            padding-bottom: 0;
            padding-left: 2em;
            padding-right: 2em;
            display: flex;
            position: absolute;
            overflow: hidden;
            .title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Dancing Script", serif;
                font-size: 12.2vw;
                font-weight: 300;
                line-height: 1em;
            }
            .sub-title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Roboto", serif;
                font-size: 1.2rem;
                font-weight: 300;
                line-height: 1em;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
            }
        }
    }
    .section-2-kld2812 {
        width: 100vw;
        margin: 0 auto;
        padding: 100px 0;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            text-align: center;
            margin: 0 auto;
            font-size: 4rem;
            width: 900px;
            font-family: "Dancing Script", serif;
            padding: 50px 0;
        }
        .btn-text {
            color: black;
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .section-3-kld2812 {
        display: flex;
        justify-content: space-between;
        width: 1300px;
        margin: 0 auto;
        height: 1300px;
        padding: 50px;
        color: black;
        .text-box-left {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 50px;
            padding: 20px;
            .left {
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .right {
                img {
                    width: 400px;
                    height: 500px;
                    object-fit: cover;
                }
            }
        }

        .text-box-center {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 50px;
            padding: 20px;
            .left {
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .right {
                img {
                    width: 400px;
                    height: 500px;
                    object-fit: cover;
                }
            }
        }

        .text-box-right {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 50px;
            padding: 1000px 20px 20px 20px;

            .right {
                img {
                    width: 400px;
                    height: 500px;
                    object-fit: cover;
                }
            }
        }
    }
    .section-4-kld2812 {
        width: 100vw;
        margin: 0 auto;
        padding: 100px 0;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-text {
            color: black;
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .section-5-kld2812 {
        width: 1300px;
        margin: 0 auto;
        .section-5-1,
        .section-5-3 {
            padding: 100px 0;
            width: 100%;
            display: flex;
            color: black;
            align-items: center;
            overflow: hidden;
            .left {
                padding-right: 50px;
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .right {
                overflow: hidden;
                img {
                    width: 100%;
                    height: 700px;
                    object-fit: cover;
                }
            }
        }
        .section-5-2 {
            width: 100%;
            display: flex;
            color: black;
            align-items: center;
            padding: 100px 0;
            .left {
                overflow: hidden;
                img {
                    width: 100%;
                    height: 700px;
                    object-fit: cover;
                }
            }
            .right {
                padding-left: 50px;
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
        }
    }
    .section-6-kld2812 {
        width: 100vw;
        margin: 0 auto;
        padding: 100px 0;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-text {
            font-family: "Dancing Script", serif;
            color: black;
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 2rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
        .accordion {
            padding: 50px 0;
            width: 1300px;
            background-color: unset !important;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            .accordion-item {
                margin-bottom: 20px;
                background-color: unset !important;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                .accordion-header {
                    .accordion-button {
                        font-size: 2rem;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-family: "Dancing Script", serif;
                        background-color: unset !important;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        .text {
                            font-family: "Roboto", sans-serif;
                            letter-spacing: 1px;
                            font-size: 1.2rem;
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
    }
    .section-7-kld2812 {
        width: 1300px;
        margin: 0 auto;
        .section-7-1 {
            padding: 100px 0;
            width: 100%;
            display: flex;
            color: black;
            align-items: center;
            overflow: hidden;
            .left {
                padding-right: 50px;
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .right {
                overflow: hidden;
                img {
                    width: 100%;
                    height: 700px;
                    object-fit: cover;
                }
            }
        }
    }
    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .photo-book-kld2812 {
        .section-1-kld2812 {
            width: 100%;
            height: 400px;
            video {
                height: 400px;
            }
            .introduce {
                height: 400px;
                justify-content: center;
            }
        }
        .section-2-kld2812 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                font-size: 2rem;
            }
            .btn-text {
                font-size: 1rem;
            }
        }
        .section-3-kld2812{
            width: 100%;
            padding:20px;
            flex-direction: column;
            height: max-content;
            .text-box-right{
                padding:500px 20px 20px 20px;
            }
        }
        .section-4-kld2812{
            width: 100%;
            padding: 0 20px 100px 0;
            .btn-text{
                font-size: 1rem;
            }
        }
        .section-5-kld2812 {
            width: 100%;
            padding: 20px;
            .section-5-1 {
                flex-direction: column;
                text-align: center;
                padding: 0;
                .left {
                    font-size: 1.5rem;
                }
                .right {
                    img {
                        width: 100%;
                        height: 400px;
                    }
                }
            }
            .section-5-2 {
                flex-direction: column;
                text-align: center;
                padding: 0;
                .left {
                    padding: 20px;
                    order: 2;
                    img {
                        width: 100%;
                        height: 400px;
                    }
                }
                .right {
                    font-size: 1.5rem;
                    order: 1;
                    padding: 0px;
                }
            }
            .section-5-3 {
                .left {
                    font-size: 1.5rem;
                }
                .right {
                    img {
                        width: 100%;
                        height: 400px;
                    }
                }
                text-align: center;
                flex-direction: column;
                padding: 0;
            }
        }
        .section-6-kld2812 {
            width: 100%;
            .accordion {
                width: 100%;
                .accordion-item {
                    .accordion-header {
                        .accordion-button {
                            font-size: 1.3rem;
                        }
                    }
                    .accordion-collapse {
                        .accordion-body {
                            .text {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
        .section-7-kld2812 {
            width: 100%;
            padding: 20px;
            .section-7-1 {
                width: 100%;
                flex-direction: column;
                .left {
                    font-size: 2rem;
                }
            }
        }
        .section-11-kld2812 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                font-size: 2rem;
            }
            .box-sent {
                .text {
                    font-size: 1rem;
                }
                .input-text {
                    font-size: 1rem;
                }
            }
            .btn-sent {
                font-size: 1.5rem;
            }
        }
    }
}
