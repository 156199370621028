.bit-infinite-menu {
    color: white;
    /* Note: this CSS is only an example, you can overlay whatever you want using the activeItem logic */
    canvas {
        background: transparent !important;
    }
    canvas#infinite-grid-menu-canvas {
        filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.6));
      }
    background: transparent !important;
    img {
        filter: drop-shadow(rgb(255, 255, 255) 0px 0px 0.55rem) !important;
    }
    #infinite-grid-menu-canvas {
        cursor: grab;
        border-radius: 15px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        outline: none;
    }

    #infinite-grid-menu-canvas:active {
        cursor: grabbing;
    }

    .action-button {
        text-decoration: none;
        position: absolute;
        color:white;
        left: 43%;
        z-index: 10;
        cursor: pointer;
        position: relative;
        width: max-content;
        overflow: hidden;
        padding: 10px 0;
        font-size: 1rem;
        letter-spacing: 1px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: #50adff;
            transition: width 0.4s ease;
        }

        &:hover {
            transition: all 0.2s ease;
            color: #50adff;

            &::after {
                width: 0%;
            }
        }
    }

    .face-title {
        user-select: none;
        position: absolute;
        font-weight: bold;
        text-align: center;
        width: 250px;
        font-size: 2rem;
        left: 1px;
        top: 50%;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .action-button-icon {
        background-color: unset !important;
        user-select: none;
        position: relative;
        text-transform: uppercase;
        top: 2px;
        font-weight: bold;
        font-size: 1.2rem;
        letter-spacing: 2px;
    }

    .face-title {
        position: absolute;
        top: 50%;
        transform: translate(60%, -50%);
    }

    .face-title.active {
        opacity: 1;
        transform: translate(20%, -50%);
        pointer-events: auto;
        transition: 0.5s ease;
    }

    .face-title.inactive {
        pointer-events: none;
        opacity: 0;
        transition: 0.1s ease;
    }

    .face-description {
        user-select: none;
        position: absolute;
        max-width: 250px;
        top: 50%;
        font-size: 1rem;
        right: 1%;
        text-align: center;
        transform: translate(0, -50%);
        .abv{
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.3rem;
        }
    }

    .face-description.active {
        opacity: 1;
        transform: translate(0%, -50%);
        pointer-events: auto;
        transition: 0.5s ease;
    }

    .face-description.inactive {
        pointer-events: none;
        transform: translate(-60%, -50%);
        opacity: 0;
        transition: 0.1s ease;
    }

    .action-button {
        position: absolute;
    }

    .action-button.active {
        bottom: 3.8em;
        width: max-content;
        opacity: 1;
        pointer-events: auto;
        transition: 0.5s ease;
    }

    .action-button.inactive {
        bottom: -80px;
        transform: scale(0);
        opacity: 0;
        pointer-events: none;
        transition: 0.1s ease;
    }
}
