.about-me-kld2112 {
    font-family: "Montserrat", serif;
    background-color: black;
    
    .section-1-kld2112 {
        width: 100vw;
        height: 100vh;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.398);
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 150px;
            .title {
                font-family: "Playfair Display", serif !important;
                width: 600px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 4rem;
                letter-spacing: 2px;
            }
            .sub-title {
                font-family: "Playfair Display", serif;
                width: 600px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 1.3rem;
                letter-spacing: 1px;
                padding: 20px 0;
            }
            .btn-intro {
                display: flex;
                gap: 20px;
                .btn-ele {
                    width: 200px;
                    border: 2px solid white;
                    transition:
                        box-shadow 0.2s ease-in-out,
                        color 0.5s ease-in-out;
                    text-align: center;
                    padding: 10px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        color: #af8647;
                        box-shadow: inset 0 0 1.5em 1.5em #fff;
                    }
                }
            }
        }
    }
    .section-2-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 0 0 100px 0;
        text-align: center;
        .title {
            height: 400px;
            object-fit: cover;
            text-align: center;
            font-size: 2rem;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title {
            text-align: center;
            font-size: 1rem;
            letter-spacing: 1px;
            width: 1000px;
            margin: 0 auto;
        }
    }
    .section-3-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 0 0 100px 0;
        .title {
            text-align: center;
            font-size: 3rem;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title {
            text-align: center;
            font-size: 2rem;
            letter-spacing: 1px;
            margin: 0 auto;
            padding: 20px 10px;
        }
        .sub-text {
            font-size: 1.5rem;
            letter-spacing: 1px;
            margin: 0 auto;
            padding: 20px 10px;
        }
        p {
            padding: 10px 0;
            letter-spacing: 1px;
            line-height: 25px;
        }
        ul {
            li {
                padding-top: 10px;
                line-height: 25px;
            }
        }
        .section-3-1 {
            padding: 100px 0;
            display: flex;
            align-items: center;
            .left {
                img {
                    width: 100%;
                    height: 600px;
                    border-radius: 15px;
                }
            }
            .right {
                padding: 0 0 0 50px;
                .title-section-3-1 {
                    font-size: 3rem;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: bold;
                    padding: 10px 0;
                }
                .sub-title-section-3-1 {
                    font-weight: 600;
                    font-size: 1.3rem;
                    padding: 10px 0;
                }
                .text-section-3-1 {
                    color: grey;
                    font-size: 15px;
                }
                .link-section-3-1 {
                    display: flex;
                    gap: 20px;
                    padding: 20px 0;
                    a {
                        padding: 13px 10px;
                        border-radius: 50%;
                        border: 1px solid #af8647;
                        justify-content: center;
                        text-align: center;
                        transition: all 0.2s ease-in-out;
                        cursor: pointer;
                        i {
                            font-size: 20px;
                            color: #af8647;
                            width: 30px;
                        }
                        &:hover {
                            background-color: #af8647;
                            i {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    .section-4-kld2112 {
        width: 1300px;
        margin: 0 auto;

        .title-section {
            text-align: center;
            font-size: 2.5rem;
            padding: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .slider-section-4 {
            padding-bottom: 100px;
            .section-4-1 {
                width: 45%;
                cursor: pointer;
                .image {
                    margin: 20px;
                    overflow: hidden;
                    border-radius: 15px;
                    img {
                        border-radius: 15px;
                        object-fit: cover;
                        height: 350px;
                        width: 100%;
                        border-radius: 15px;
                        border: none;
                        transition: all 0.2s;
                        &:hover {
                            overflow: hidden;
                            border-radius: 15px;
                            transform: scale(1.21);
                        }
                    }
                }
                .title {
                    font-size: 2rem;
                    letter-spacing: 2px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 20px;
                }
                .sub-title {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 0 20px;
                }
            }

            .btn-prev-next {
                transition: all 0.2s;
                &:hover {
                    background-color: #af8647;
                    .fa-chevron-left,
                    .fa-chevron-right {
                        color: white !important;
                    }
                }
            }
        }
    }
    .section-5-kld2112 {
        --sectionBackground: linear-gradient(rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.57)),
            url(https://res.cloudinary.com/dolydpat4/image/upload/v1734809442/element-bg2-1_exrewp.webp);
        background-image: none;
        transition: all 0.5s;
        clip-path: polygon(0px -2px, 100% -2px, 100% 102%, 0% 102%);
        .testimonials-section:before {
            display: block;
            content: "";
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: var(--sectionBackground) center no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            position: fixed;
            z-index: -1;
            pointer-events: none;
        }
        .testimonials-section {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 900px;
            height: 700px;
            margin: 0 auto;
            padding: 150px 0;
            gap: 30px;
            transition: max-height 0.5s;
            .title {
                font-size: 1.4rem;
                padding-bottom: 30px;
                letter-spacing: 3px;
                transition: all 0.5s;
            }
            .text {
                padding-bottom: 30px;
                font-size: 15px;
                line-height: 25px;
                max-height: 500px;
                transition: max-height 0.5s ease;
                transition: all;
            }
        }
    }

    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .about-me-kld2112 {
        .section-1-kld2112 {
            width: 100%;
            height: 400px !important;
            img {
                height: 400px;
            }
            .introduce {
                height: 400px;
                padding: 60px 20px;
                .title {
                    width: 100%;
                    font-size: 1.7rem;
                }
                .sub-title {
                    width: 100%;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                .btn-intro {
                    .btn-ele {
                        width: 150px;
                        font-size: 13px;
                        border:1px solid white;
                    }
                }
            }
        }
        .section-2-kld2112 {
            width: 100%;
            padding:50px 20px;
            .title{
                height: 200px;
            }
            .sub-title{
                width: 100%;
            }
        }
        .section-3-kld2112 {
            width: 100%;
            padding:20px;
            .title{
                font-size: 2rem;
                text-transform: uppercase;
                font-weight: bold;
            }
            .sub-title{
                font-size: 1rem;
                font-weight: bold;
            }
            p, ul, li{
                font-size: 14px;
            }
            .section-3-1{
                padding-bottom:0px;
                flex-direction: column;
                .left{
                    img{
                        height: 450px;
                    }
                }
                .right{
                    width: 100%;
                    padding: 20px 0;
                    text-align: center;
                    .title-section-3-1{
                        font-size: 1.5rem;
                    }
                    .sub-title-section-3-1{
                        font-size: 1rem;
                    }
                    .text-section-3-1{
                        font-size: 13px;
                    }
                    .link-section-3-1{
                        justify-content: center;
                    }
                }
            }
        }
        .section-4-kld2112 {
            width: 100%;
            .title-section {
                font-size: 1.5rem;
            }
            .slider-section-4 {
                padding-bottom: 100px;
                .btn-prev-next{
                    display: none;
                }
                .section-4-1 {
                    width: 100%;
                    cursor: pointer;
                    
                    .image {
                        margin: 20px;
                        overflow: hidden;
                        border-radius: 15px;
                        img {
                            height: 300px;
                        }
                    }
                    .title {
                        text-align: center;
                        font-size: 1.5rem;
                        padding: 0 20px;
                    }
                    .sub-title {
                        text-align: center;
                        font-size: 1rem;
                        font-weight: normal;
                        padding: 10px 20px;
                    }
                }
    
                .btn-prev-next {
                    transition: all 0.2s;
                    &:hover {
                        background-color: #af8647;
                        .fa-chevron-left,
                        .fa-chevron-right {
                            color: white !important;
                        }
                    }
                }
            }
        }
        .section-5-kld2112 {
            width: 100%;
            .testimonials-section {
                width: 100%;
                height: 650px;
                flex-direction: column;
                .col-md-3{
                    svg{
                        padding:20px;
                        width: 150px;
                        height: 150px;
                    }
                }
                .col-md-9{
                    width: 100%;
                    padding:20px;
                }
                .title {
                    font-size: 1.3rem;
                }
                .text {
                    padding-bottom: 20px;
                    font-size: 13px;
                }
                .sub-title{
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
