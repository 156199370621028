.real-kld2812 {
    .section-1-kld2812 {
        width: 100vw;
        height: 30vh;
        transition: all 1.4s;
        .introduce {
            z-index: 6;
            mix-blend-mode: exclusion;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            height: 30vh;
            padding: 2rem;
            display: flex;
            position: absolute;
            overflow: hidden;
            .title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Dancing Script", serif;
                font-size: 5rem;
                font-weight: 300;
                line-height: 1em;
            }
            .sub-title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Roboto", serif;
                font-size: 1.2rem;
                font-weight: 300;
                line-height: 1em;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
            }
        }
    }
    .section-2-kld2812 {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 50px;
        .section-2-1 {
            display: flex;
            padding: 20px;
            background-color: white;
            width: 90%;
            gap: 20px;
            transition: all 0.5s ease-in-out;
            transform: translateX(0px);
            cursor: pointer;
            .left {
                padding: 0 20px 0 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                transition: all 0.2s ease;
                .title,
                .sub-text {
                    font-size: 1.2rem;
                    font-family: "Roboto", sans-serif;
                    letter-spacing: 2px;
                }
                .text {
                    font-size: 3rem;
                    font-family: "Dancing Script", sans-serif;
                }
            }
            .right {
                img {
                    width: 400px;
                }
                .show-image {
                    display: block;
                }
                .off-image {
                    display: none;
                }
            }
            &:hover {
                transition: all 0.5s ease-in-out;
                transform: translateX(50px);
                .left {
                    .text {
                        color: pink;
                        transition: all 0.2s ease;
                    }
                }
                .right {
                    img {
                        width: 400px;
                    }
                    .show-image {
                        display: none;
                    }
                    .off-image {
                        display: block;
                    }
                }
            }
        }
    }

    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .real-kld2812 {
        .section-1-kld2812 {
            width: 100%;
            height: 300px;
            .introduce {
                width: 100%;
                height: 300px;
                justify-content: center;
                .title {
                    font-size: 2rem;
                }
            }
        }
        .section-2-kld2812 {
            .section-2-1 {
                flex-direction: column;
                .left {
                    width: 100%;
                    padding: 20px;
                    .title{
                        font-size: 1rem;
                    }
                    .text{
                        padding:20px 0;
                        font-size: 2rem;
                    }
                    
                }
                .right{
                    overflow: hidden;
                    .off-image{
                        width: 90%;
                        padding-right:10px;
                    }
                }
            }
        }
        .section-11-kld2812{
            width: 100%;
            padding:20px;
            .title{
                width: 100%;
                font-size: 2rem;
            }
            .box-sent{
                .text{
                    font-size: 1rem;
                }
                .input-text{
                    font-size: 1rem;
                }
            }
            .btn-sent{
                font-size: 1.5rem;
            }
        }
    }
}
