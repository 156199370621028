.sixth-layout-kld1901 {
    font-family: "Play", serif;
    .section-1-kld1901 {
        margin: 0 auto;
        display: flex;
        padding: 150px 0;
        .box-banner {
            width: 100%;
            padding: 0 125px 0 0;
            img {
                animation: fadeInSlide 0.5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 400px;
                object-fit: cover;
                z-index: 1;
                top: 150px;
                width: 92.5%;
                border-radius: 0 15px 15px 0;
            }
            .text-banner {
                background: linear-gradient(to right, #000000ce, #eeeeee23);
                height: 400px;
                justify-content: center;
                z-index: 2;
                position: relative;
                color: white;
                display: flex;
                flex-direction: column;
                .title {
                    width: 1280px;
                    margin: 0 auto;
                    font-size: 3rem;
                    text-transform: uppercase;
                    padding: 20px 0;
                    font-weight: bold;
                    letter-spacing: 2px;
                }
                .sub-title {
                    width: 1280px;
                    margin: 0 auto;
                    font-size: 16px;
                    .sp1 {
                        color: rgb(169, 169, 169);
                    }
                    .sp2 {
                        font-size: 20px;
                        color: white;
                    }
                }
            }
        }
    }
    .section-2-kld1901 {
        width: 1400px;
        margin: 0 auto;
        padding: 0 0 125px 0;
        .section-2-1-kld1901 {
            display: flex;
            flex-direction: column;
            gap: 10px;
            letter-spacing: 1px;
        }
    }
    .section-3-kld1901 {
        margin: 0 auto;
        padding: 50px 0;
        position: relative;
        overflow: hidden;
        .background {
            background-color: #af8647;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 200px;
            z-index: 1;
        }
        .section-3-1-kld1901 {
            background-color: rgb(245, 245, 245);
            padding: 50px;
            width: 1400px;
            position: relative;
            z-index: 2;
            margin: 0 auto;
            border-radius: 15px;
            display:flex;
            .left {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .title {
                    font-weight: bold;
                    font-size: 2rem;
                    letter-spacing: 1px;
                }
                .bxct{
                    display:flex;
                    flex-direction: column;
                    gap:10px;
                    .tmln{
                        display:flex;
                        gap:20px;
                        padding: 0 0 0 10px;
                        .tm{
                            color:#af8647;
                            text-transform: uppercase;
                        }
                    }
                    .cont{
                        padding:0 0 20px 10px;
                        border-left: 1px solid #af8647;
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;
                align-items: center;
                .box-img {
                    object-fit: cover;
                    box-shadow: -20px 20px 0em 0em #af8647;
                    img {
                        width: 500px;
                        height: 500px;
                        object-fit: cover;
                    }
                }
                .text {
                    font-size: 1.1rem;
                    width: 90%;
                    text-align: center;
                    padding: 0 5px 5px 5px;
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }
    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
