.footer-kld2112 {
    background-color: black;
    font-family: "Montserrat", serif;
    .section-1-kld2112 {
        width: 1300px;
        margin: 0 auto;
        img {
            width: 200px;
        }
    }
    .section-2-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        .title {
            font-size: 2rem;
            padding: 0 0 10px 0;
            letter-spacing: 1px;
        }
        .sub-title {
            font-size: 1rem;
            color: grey;
            padding: 0 0 5px 0;
        }
    }
    .section-3-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 50px 10px;
        align-items: center;
        .left {
            padding: 0;
            .col-md-2 {
                span {
                    cursor: pointer;
                    transition: all 0.2s;
                    &:hover {
                        color: #af8647;
                    }
                }
            }
        }
        .right {
            display: flex;
            gap: 20px;
            padding: 0;
            justify-content: end;
            div {
                padding: 13px 10px;
                border-radius: 50%;
                border: 1px solid #af8647;
                justify-content: center;
                text-align: center;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                i {
                    font-size: 20px;
                    color: #af8647;
                    width: 30px;
                }
                &:hover {
                    background-color: #af8647;
                    i {
                        color: white;
                    }
                }
            }
        }
    }
    .section-4-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
    }
    .section-6-kld2112 {
        img {
            width: 100%;
            height: 700px;
            position: absolute;
        }
        .section-6-1 {
            position: relative;
            height: 700px;
            background-color: rgba(0, 0, 0, 0.39);
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            width: 100%;
            .title {
                width: 800px;
                margin: 0 auto;
                font-size: 3rem;
            }
            .text {
                width: 800px;
                margin: 0 auto;
            }
            .btn-link {
                margin: 0 auto;
                width: 300px;
                background-color: unset;
                font-size: 1rem;
                color: white;
                text-decoration: none;
                border: 2px solid white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 15px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    color: #af8647;
                    box-shadow: inset 0 0 1.5em 1.5em #fff;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .footer-kld2112 {
        .section-1-kld2112 {
            width: 100%;
            padding:10px 20px;
            img {
                width: 150px;
            }
        }
        .section-2-kld2112 {
            width: 100%;
            padding:10px 20px;
            gap:30px;
            .title {
                font-size: 1.5rem;
                padding: 0 0 10px 0;
            }
            .sub-title {
                font-size: 13px;
            }
        }
        .section-3-kld2112 {
            width: 100%;
            padding: 20px;
            flex-direction: column;
            .left {
                .col-md-2 {
                    padding:10px 15px;
                }
            }
            .right {
                padding: 20px;
                justify-content: start;
            }
        }
        .section-4-kld2112 {
            width: 100%;
            padding:0 20px 20px 20px;
            color: white;
        }
        .section-6-kld2112 {
            img {
                width: 100%;
                height: 600px;
                position: absolute;
            }
            .section-6-1 {
                position: relative;
                height: 600px;
                background-color: rgba(0, 0, 0, 0.39);
                color: white;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                width: 100%;
                .title {
                    width: 100%;
                    font-size: 2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .text {
                    width: 100%;
                    margin: 0 auto;
                    padding: 20px;
                }
                .btn-link {
                    width: max-content;
                    font-size: 13px;
                    border: 1px solid white
                }
            }
        }
    }
}
