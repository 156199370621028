.footer-kld1312 {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    .section-9 {
        background-color: rgb(19 55 95);
        color: white;
        .row {
            padding: 100px 0;
            width: 1200px;
            margin: 0 auto;
            align-items: center;
            .left {
                .title {
                    // text-transform: uppercase;
                    color: #50adff;
                    letter-spacing: 1px;
                }
                .sub-title {
                    font-size: 2rem;
                    letter-spacing: 1px;
                }
            }
            .right {
                display: flex;
                height: fit-content;
                .input {
                    padding: 20px;
                    background-color: #597a9f3b;
                    color: white;
                    border: none;
                    border-radius: 15px 0 0 15px;
                    &::placeholder {
                        color: rgb(175, 175, 175);
                    }
                }
                .submit {
                    padding: 20px;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                    background-color: #ececec;
                    color: rgb(20 97 182);
                    border-radius: 0 15px 15px 0;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        background-color: #50adff;
                        color: white;
                    }
                }
            }
        }
    }
    .section {
        width: 1200px;
        margin: 0 auto;
        padding: 50px 0 20px 0;
        .section-1 {
            img {
                width: 150px;
            }
            .text {
                padding: 30px 10px;
            }
        }
        .section-2 {
            .title {
                font-size: 2.5rem;
            }
            .content {
                font-size: 16px;
                padding: 20px 0;
            }
        }
        .section-3 {
            .title {
                font-size: 2.5rem;
            }
            .content {
                padding: 20px 0;
                .col-6 {
                    cursor: pointer;
                    padding-bottom: 10px;
                    &:hover {
                        color: rgb(20 97 182);
                    }
                }
            }
        }
    }
}
@media (max-width: 950px) {
    .footer-kld1312{
        .section-9 {
            width: 100%;
            .row{
                width: 100%;
                padding:50px 10px;
                .left{
                    .sub-title{
                        font-size:1.5rem;
                        padding:20px 0;
                    }
                }
            }
        }
        .section{
            width: 100%;
            .section-2{
                padding:0px 20px;
            }
            .section-3{
                padding:0px 20px
            }
            hr{
                padding:10px;
                width: 90%;
                margin: 0 auto;
            }
        }
    }
}