.blog-kld1312 {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    .section-1 {
        padding: 104px 0 0 0;
        .banner {
            animation: fadeInSlide 0.5s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 500px;
            object-fit: cover;
            z-index: 1;
            top: 100px;
        }
        .text-banner {
            background-color: rgba(0, 0, 0, 0.517);
            height: 496px;
            justify-content: center;
            align-items: center;
            z-index: 2;
            position: relative;
            color: white;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 3rem;
                text-transform: uppercase;
                width: 60%;
                text-align: center;
                padding: 20px 0;
                font-weight: bold;
                letter-spacing: 2px;
            }
            .sub-title {
                font-size: 20px;
            }
        }
    }
    .section-2 {
        width: 1200px;
        margin: 0 auto;
        padding: 50px 10px;
        .eblog {
            background-color: rgb(245, 245, 245);
            border-radius: 15px;
            margin-bottom: 40px;
            img {
                width: 100%;
                border-radius: 15px 15px 0 0;
            }
            .details {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 30px;
                .title {
                    font-size: 2rem;
                    font-weight: bold;
                }
                .time {
                    letter-spacing: 2px;
                }
                .text {
                    font-size: 1.1rem;
                }
                .button-blog {
                    width: max-content;
                    padding: 15px;
                    border: none;
                    background-color: rgb(24, 72, 126);
                    border-radius: 15px;
                    color: white;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        background-color: #50adff;
                    }
                }
            }
        }
        .button-page-news {
            display: flex;
            gap: 10px;
            .page-button {
                padding: 10px 15px;
                border: none;
                background-color: rgb(24, 72, 126);
                border-radius: 15px;
                color: white;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background-color: #50adff;
                }
            }
        }
        .right {
            padding: 0 20px;
            .title {
                padding: 20px;
                font-weight: 600;
                border-bottom: 4px solid rgb(24, 72, 126);
                background-color: rgb(245, 245, 245);
                border-radius: 15px;
            }
            .text {
                padding: 30px 0;
                color: grey;
            }
            .news {
                display: flex;
                padding: 20px 0;
                gap: 20px;
                align-items: center;
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 15px;
                }
                .details-new {
                    .title-news {
                        font-weight: 600;
                    }
                    .time-news {
                        padding: 20px 0 0 0;
                        font-size: 13px;
                        color: grey;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                }
            }
            .list-tag {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                padding: 20px 0;
                .tag {
                    width: max-content;
                    padding: 10px;
                    background-color: rgb(24, 72, 126);
                    color: white;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 13px;
                    letter-spacing: 1px;
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    &:hover {
                        background-color: #50adff;
                    }
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.5s ease;
    }

    .animate-section.animate {
        opacity: 1; 
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .blog-kld1312 {
        .section-1 {
            padding: 90px 0 0 0;
            .banner {
                width: 100%;
                height: 300px;
                top:90px
            }
            .text-banner {
                width: 100%;
                height: 300px;
                padding: 20px;
                justify-content: center;
                .title {
                    font-size: 1.5rem;
                }
                .sub-title {
                    font-size: 1rem;
                }
            }
        }
        .section-2{
            width: 100%;
            padding: 30px;
            .row{
                gap:30px;
                .right{
                    padding:10px;
                }
            }
        }
    }
}
