.course-detail-kld1312 {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    .section-1 {
        padding: 104px 0 0 0;
        .banner {
            animation: fadeInSlide 0.5s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 400px;
            object-fit: cover;
            z-index: 1;
            top: 100px;
        }
        .text-banner {
            background-color: rgba(0, 0, 0, 0.517);
            height: 396px;
            justify-content: center;
            align-items: center;
            z-index: 2;
            position: relative;
            color: white;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 3rem;
                text-transform: uppercase;
                width: 60%;
                text-align: center;
                padding: 20px 0;
                font-weight: bold;
                letter-spacing: 2px;
            }
            .sub-title {
                font-size: 20px;
            }
        }
    }
    .section-2 {
        width: 1200px;
        margin: 0 auto;
        padding: 100px 0;
        .details {
            padding: 0px;
            display: flex;
            gap: 20px;
            .left {
                padding: 0;
                .e1st {
                    height: max-content;
                    display: flex;
                    color: grey;
                    width: 100%;

                    .wishlist {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        padding: 20px;
                        width: 30%;
                        .fa-bookmark-o:before {
                            content: "\f097";
                        }
                        .fa {
                            color: #50adff;
                            border-color: white; // Thêm border để border-color hoạt động
                            display: inline-block;
                            font: normal normal normal 14px / 1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            cursor: pointer;
                        }
                    }
                    .img-auth {
                        width: 30%;
                        display: flex;
                        padding: 10px 20px 10px 10px;
                        gap: 20px;
                        border-right: 1px solid #d6d6d6;
                        align-items: center;
                        img {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                        }
                        .title {
                            color: grey;
                        }
                        .name {
                            color: #50adff;
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                    }
                    .category {
                        display: flex;
                        padding: 10px 20px;
                        border-right: 1px solid #d6d6d6;
                        align-items: center;
                        width: 20%;
                        .title {
                            color: grey;
                        }
                        .name {
                            color: #50adff;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                    .review {
                        padding: 10px 20px;
                        border-right: 1px solid #d6d6d6;
                        align-items: center;
                        width: 20%;
                        .title {
                            color: grey;
                        }
                        .star {
                            padding: 5px 0;
                        }
                    }
                }
                .course-information {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 20px 10px;
                    .title {
                        font-size: 2rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }
                    .sub-title {
                        font-size: 1.5rem;
                        font-weight: 600;
                        letter-spacing: 1px;
                    }
                    .text {
                        font-size: 1.1rem;
                        color: grey;
                    }
                    .schedule {
                        display: flex;
                        flex-wrap: wrap;
                        .col-md-4 {
                            padding: 10px 0;
                            span {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }
            .right {
                .right-box {
                    background-color: rgb(246 246 246);
                    padding: 50px;
                    border-radius: 15px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .price {
                        font-size: 3rem;
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                    .btn-price {
                        text-decoration: none;
                        cursor: pointer;
                        padding: 20px;
                        color: white;
                        background-color: rgb(19 55 95);
                        border-radius: 15px;
                        text-transform: uppercase;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        letter-spacing: 1px;
                        &:hover {
                            background-color: #50adff;
                        }
                    }
                    .text-price {
                        color: grey;
                    }
                }
            }
        }
    }
    .section-3 {
        width: 1200px;
        margin: 0 auto;
        padding: 0 10px 50px 10px;
        .tab-course {
            padding: 0;
            text-align: center;
            position: relative;

            .tab-detail {
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                padding: 20px;
                cursor: pointer;
            }

            .bottom-slide-bar {
                position: absolute;
                bottom: 0;
                height: 13px;
                transition: all 0.3s ease;
                color: #375eef;
                .bottom-slide-bar-border {
                    border-top: 1px solid #375eef;
                    &:before {
                        content: " ";
                        width: 0px;
                        height: 0px;
                        border-width: 12px 12px 0px 12px;
                        border-style: solid;
                        display: block;
                        margin: 0px auto;
                        border-right-color: transparent;
                        border-bottom-color: transparent;
                        border-left-color: transparent;
                    }
                }
                &:after {
                    content: " ";
                    width: 0px;
                    height: 0px;
                    border-width: 12px 12px 0px 12px;
                    border-style: solid;
                    border-color: #ffffff transparent transparent;
                    display: block;
                    margin: -13px auto 0px;
                }
            }
        }
    }
    .section-4 {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 50px;
        .review {
            animation: clickActive 1s forwards;
            @keyframes clickActive {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            .left {
                text-align: center;
                .title-review {
                    font-size: 5rem;
                }
                .total-rating {
                    padding: 10px;
                    font-size: 1.1rem;
                    color: grey;
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 20px;

                .line-rate {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;
                    height: 20px;

                    span {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }

                    .line-color-wrapper {
                        position: relative;
                        width: 100%;
                        height: 20px;

                        .line-color-default {
                            background-color: rgb(233, 233, 214);
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }

                        .line-color-active {
                            background-color: #fed35b;

                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
        .introduce {
            padding: 0 10px;
            animation: clickActive 1s forwards;
            @keyframes clickActive {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            .left {
                img {
                    border-radius: 50%;
                }
            }
            .right {
                .title {
                    font-size: 2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                .text {
                    color: grey;
                    width: 700px;
                }
            }
        }
        .list-curriculum {
            padding: 0 10px;
            display: flex;
            gap: 20px;
            animation: clickActive 1s forwards;
            @keyframes clickActive {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            .curriculum {
                padding-bottom: 10px;
                button {
                    width: 100%;
                    text-align: start;
                    padding: 10px 30px;
                    border: none;
                    background-color: #f4f4f4;
                    border-radius: 15px;
                    display: flex;
                    justify-content: space-between;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                .card-body123 {
                    padding: 20px;

                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    a {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        text-decoration: none;
                        color: black;
                        padding: 0 10px;
                        border: 1px solid rgb(235, 235, 235);
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        align-items: center;
                        span {
                            .name {
                                font-size: 1.1rem;
                            }
                            .time {
                                color: grey;
                            }
                        }
                        .preview {
                            background-color: #375eef;
                            color: white;
                            padding: 5px 10px;
                            font-size: 13px;
                            border-radius: 15px;
                        }
                    }
                }
            }
        }
    }
    .section-5 {
        width: 1200px;
        margin: 0 auto;
        padding: 100px 0px;

        .col-md-4 {
            padding: 20px;
            gap: 20px;
            display: flex;
            flex-wrap: wrap;
            .course {
                text-decoration: none;
                border: 1px solid rgb(233, 232, 232);
                border-radius: 15px;
                .detail {
                    padding: 30px;
                    .price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .number-price {
                            font-size: 2rem;
                            color: #50adff;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                        .fa-bookmark-o:before {
                            content: "\f097";
                        }
                        .fa {
                            color: #50adff;
                            border-color: white; // Thêm border để border-color hoạt động
                            display: inline-block;
                            font: normal normal normal 14px / 1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            cursor: pointer;
                        }
                    }
                    .member-course {
                        padding: 20px 0;
                        display: flex;
                        gap: 20px;
                        .left {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            color: grey;
                        }
                        .right {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            color: grey;
                        }
                    }
                    .review {
                        display: flex;
                        justify-content: space-between;
                        .type-course {
                            font-size: 1rem;
                            color: #50adff;
                            letter-spacing: 1px;
                        }
                    }
                    .name-course {
                        font-size: 2rem;
                        font-weight: 600;
                        padding: 10px 0;
                        color: black;
                    }
                    .text-course {
                        color: grey;
                        padding: 20px 0;
                    }
                }
            }
        }
        img {
            padding: 0;
            border-radius: 15px 15px 0 0;
            object-fit: cover;
            width: 100%;
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.5s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .course-detail-kld1312 {
        .section-1 {
            padding: 90px 0 0 0;
            .banner {
                width: 100%;
                height: 300px;
                top: 90px;
            }
            .text-banner {
                width: 100%;
                height: 300px;
                padding: 20px;
                justify-content: center;
                .title {
                    font-size: 1.5rem;
                }
                .sub-title {
                    font-size: 1rem;
                }
            }
        }
        .section-2 {
            width: 100%;
            padding: 30px 10px;
            .details {
                display: flex;
                flex-direction: column;
                width: 100%;
                .left {
                    width: 100%;
                    .e1st {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 10px;
                        .img-auth,
                        .category,
                        .review,
                        .wishlist {
                            width: 50%;
                            border: none;
                        }
                    }
                    .course-information {
                        padding: 30px;
                        .schedule{
                            flex-direction: column;
                        }
                    }
                }
            }
        }
        .section-3 {
            width: 100%;
            padding: 10px;
            .tab-course {
                flex-wrap: wrap;
                display: flex;
                justify-content: center;
                .tab-detail {
                    width: max-content;
                    font-size: 14px;
                }
                .bottom-slide-bar {
                    left: 0% !important;
                    width: 100% !important;
                }
            }
        }
        .section-4 {
            width: 100%;
            .review,
            .introduce {
                padding: 30px;
            }
            .introduce {
                width: 100%;
                text-align: center;
                gap: 20px;
                .left {
                    width: 100%;
                }
                .right {
                    width: 100%;
                    .text {
                        width: 100%;
                    }
                }
            }
        }
        .section-5 {
            width: 100%;
            padding: 10px;
        }
    }
}
