.loader-kld2112 {
    position: fixed;
    inset: 0;
    width: 0;
    height: 100vh;
    z-index: 999999;
    background-color: #252525;
    will-change: animation;
    animation: expandWidth 0.9s cubic-bezier(0.76, 0, 0.24, 1);

    @keyframes expandWidth {
        0% {
            width: 100vw;
        }
        100% {
            width: 0;
        }
    }
}
.loader1-kld2112 {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background-color: #252525;
    will-change: animation;
    animation: expandWidth1 0.9s cubic-bezier(0.76, 0, 0.24, 1);

    @keyframes expandWidth1 {
        0% {
            width: 0;
        }
        100% {
            width: 100vw;
        }
    }
}
