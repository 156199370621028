h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}
// .dpkCursor {
//     height: 40px;
//     width: 40px;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 999999999;
//     pointer-events: none;

//     &::before {
//         content: "";
//         transform: rotate(45deg);
//         position: absolute;
//         border-radius: 12px;
//         width: 40px;
//         height: 40px;
//         background: #8888888d;
//         -webkit-box-sizing: border-box;
//         box-sizing: border-box;
//     }
// }

// .dpkCursor-hover {
//     margin-left: -2px;
//     margin-top: -2px;
//     width: 6px;
//     height: 6px;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 999999999;
//     pointer-events: none;
//     &::before {
//         content: "";
//         transform: rotate(45deg);
//         position: absolute;
//         border-radius: 12px;
//         border: none;
//         transition:
//             background 0.5s,
//             box-shadow 0.5s;
//         box-shadow: 0px 0px 20px 18px #888888;
//         margin-left: -20px;
//         margin-top: -20px;
//         width: 60px;
//         height: 60px;

//         /* scale:1.2; */
//         background: #8888884d;
//         -webkit-box-sizing: border-box;
//         box-sizing: border-box;
//     }
// }
.background-dot {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0); /* Mờ background */
    z-index: -1; /* Đặt phía sau nội dung */
    overflow: hidden;

    .dot {
        position: absolute;
        width: 5vw; /* Kích thước dựa trên viewport width */
        height: 5vw; /* Giữ tỷ lệ vuông */
        max-width: 150px; /* Kích thước tối đa */
        max-height: 150px; /* Giới hạn kích thước */
        min-width: 50px; /* Kích thước tối thiểu */
        min-height: 50px; /* Giới hạn nhỏ nhất */
        border-radius: 50%;
        will-change: transform;
    transition: transform 0.1s linear;
        box-shadow:
            0 0 5vw 2vw rgba(255, 255, 255, 0.5),
            0 0 10vw 4vw rgba(255, 255, 255, 0.3),
            0 0 15vw 6vw rgba(255, 255, 255, 0.2); /* Hiệu ứng ánh sáng loen */
        filter: blur(100px); /* Làm mờ thêm */
        mix-blend-mode: screen; /* Blend mode để hiệu ứng ánh sáng hòa vào nền */
    }

    .top-left {
        top: 15%;
        left: 15%;
        background: rgba(255, 191, 0, 1); /* Màu đỏ mờ */
    }

    .top-right {
        top: 15%;
        right: 15%;
        background: rgba(0, 255, 106, 1); /* Màu đỏ mờ */
    }

    .bottom-left {
        bottom: 15%;
        left: 15%;
        background: rgba(8, 0, 255, 1); /* Màu tím mờ */
    }

    .bottom-right {
        bottom: 15%;
        right: 15%;
        background: rgba(255, 0, 191, 1); /* Màu xanh lá mờ */
    }
}
.header-sell-website {
    position: absolute;
    width: 100%;
    transition: all 0.5s;
    z-index: 100;
    .navbar-sell-website {
        display: flex;
        /* padding: 22px 10px; */
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        &::before {
            display: unset !important;
            content: none !important;
        }
        &::after {
            display: unset !important;
            content: none !important;
        }
        .hamburger-btn {
            display: none;
            color: black;
            cursor: pointer;
            font-size: 1.5rem;
        }
        .logo {
            order: 1;
            gap: 10px;
            display: flex;
            align-items: center;
            text-decoration: none;
            img {
                width: 300px;
                height: 100px;
                padding: 10px 0;
                object-fit: none; /* Giữ kích thước thật của logo */
                object-position: center; /* Lấy phần trung tâm của logo */
            }
        }
        .logo-res {
            order: 1;
            gap: 10px;
            display: flex;
            align-items: center;
            text-decoration: none;
            display: none;
            img {
                width: 60px;
            }
        }
        .links {
            order: 2;
            display: flex;
            gap: 35px;
            margin: 0;
            list-style: none;
            align-items: center;
            .dropdown {
                position: relative;
                .dropdown-menu {
                    width: max-content;
                    cursor: default;
                    position: absolute;
                    background: unset;
                    border: none;
                    display: none;
                    flex-direction: column;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    padding: 25px 0;
                    li:hover {
                        /* padding: 10px 15px; */
                        background: #468df13e;
                        color: black;
                    }
                    li a:hover {
                        // padding: 10px 20px;
                        background: unset;
                        color: #50adff !important;
                    }
                    a {
                        cursor: pointer;
                        color: white;
                        padding: 15px;
                        background: black;
                        &:hover {
                            color: #50adff !important;
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        display: flex;
                        animation: fade-in 0.3s ease-in-out forwards;
                    }
                    .navbar-sell-website .links .dropdown .dropdown-menu li {
                        padding: 10px;
                    }
                }
            }
            a {
                color: white;
                text-decoration: none;
                transition: 0.1s ease;
                &:hover {
                    color: #50adff;
                }
            }
        }
        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            display: none;
            color: black;
            cursor: pointer;
        }
        .back-btn {
            position: absolute;
            left: 20px;
            top: 20px;
            display: none;
            color: black;
            cursor: pointer;
        }
        .logged-btn {
            display: flex;
            order: 3;
            border: none;
            outline: none;
            color: black;
            font-size: 1rem;
            font-weight: 600;
            border-radius: 15px;
            cursor: pointer;
            transition: 0.15s ease;
            align-items: center;
            .logged-content {
                color: black;
                text-decoration: none;
                display: flex;
                font-size: 16px;
                align-items: center;
                margin-right: 10px;
                .logged-text {
                    margin-left: 10px;
                    white-space: nowrap;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 60px;
                }
            }
            .logout-content {
                display: flex;
                align-items: center;
                margin-left: 10px;
                /* margin-right: 10px; */
            }
            .cart-content {
                display: flex;
                align-items: center;
                margin-left: 10px;
                margin-right: 10px;
            }
            .cart-content {
                position: relative;
                img {
                    width: 25px;
                    height: 25px;
                }
                .number {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    background-color: red;
                    color: white;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                }
            }
        }
        .login-btn {
            order: 3;
            border: none;
            outline: none;
            background: #50adff;
            /* opacity: 0.1; */
            color: white;
            font-size: 13px;
            font-weight: 600;
            padding: 10px 18px;
            border-radius: 15px;
            cursor: pointer;
            transition: 0.15s ease;
            &:hover {
                background: #28669b;
            }
        }
        .button-portfolio-website {
            display: none;
        }
    }
    &.header-sell-website-scroll {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.871);
        box-shadow: 2px -3px 18px #888888;
        transition: all 0.5s;
        .links {
            a {
                color: white;
            }
        }
    }
    .animate-kassdev-top-5s {
        opacity: 0;
        animation: fade-down 5s forwards;
    }
    .animate-kassdev-top-2s {
        opacity: 0;
        animation: fade-down 2s forwards;
    }
    .animate-kassdev-top-4s {
        opacity: 0;
        animation: fade-down 4s forwards;
    }
    .animate-kassdev-top-3s {
        opacity: 0;
        animation: fade-down 3s forwards;
    }
    .animate-kassdev-top-1s {
        opacity: 0;
        animation: fade-down 1s forwards;
    }
    .animate-kassdev-right-2s {
        opacity: 0;
        animation: fade-right 2s forwards;
    }
    .animate-kassdev-right-1s {
        opacity: 0;
        animation: fade-right 1s forwards;
    }
    .animate-kassdev-left-2s {
        opacity: 0;
        animation: fade-left 2s forwards;
    }
    .animate-kassdev-left-1s {
        opacity: 0;
        animation: fade-left 1s forwards;
    }

}
.mobile-header {
    display: none;
}
.mobile-menu,
.select-menu {
    display: none !important;
}
/* Keyframes cho animation */
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-30px);
        /* Dịch chuyển lên trên */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Khôi phục vị trí ban đầu */
    }
}
@keyframes fade-down {
    from {
        opacity: 0;
        transform: translate(-20px, -50px);
        /* Dịch chuyển lên trên */
    }

    to {
        opacity: 1;
        transform: translate(0px, 0px);
        /* Khôi phục vị trí ban đầu */
    }
}
@keyframes fade-right {
    from {
        opacity: 0;
        transform: translate(30px, 0);
        /* Dịch chuyển lên trên */
    }

    to {
        opacity: 1;
        transform: translate(0px, 0px);
        /* Khôi phục vị trí ban đầu */
    }
}
@keyframes fade-left {
    from {
        opacity: 0;
        transform: translate(-30px, 0);
        /* Dịch chuyển lên trên */
    }

    to {
        opacity: 1;
        transform: translate(0px, 0px);
        /* Khôi phục vị trí ban đầu */
    }
}
.swal2-shown{
    
    .swal2-backdrop-show{
        
        .swal2-modal{
            .swal2-question{
                border-color: #50adff;
            }
            .swal2-icon-content{
                color: #50adff;
            }
            .swal2-title{
                font-size: 1.1rem !important;
            }
            .swal2-html-container{
                font-size: 1rem !important;
            }
            .swal2-confirm{
                background-color: #50adff;
                border-radius: 15px;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .swal2-cancel{
                background-color: unset;
                border-radius: 15px;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                border: 1px solid #50adff;
            }
            letter-spacing: 1px;
            border: 1px solid #50adff92;
            border-radius: 15px;
            background: rgba(0, 0, 0, 0.435) !important;
            backdrop-filter: blur(20px);
            color:white;
        }
    }
}
@media (max-width: 950px) {
    .background-dot .dot {
        transition: transform 0.3s ease-out;
        width: 20vw; /* Tăng kích thước tương đối */
        height: 20vw;
        filter: blur(70px); /* Giảm blur để dễ nhìn hơn */
    }
    .background-dot{
        .bg-mobile-1{
            width: 100% !important;
        }
        .bg-mobile-2{
            display: none;
        }
    }
    .dpkCursor-hover {
        display: none;
    }
    .dpkCursor {
        display: none;
    }
    .header-sell-website {
        display: none;
    }
    // .select-library {
    //     display: flex !important;
    //     flex-direction: column;
    //     gap: 30px;
    //     justify-content: center;
    //     li{
    //         list-style: none;
    //         padding:0 10px;
    //     }
    //     margin:0;
    //     overflow: hidden; /* Ẩn nội dung vượt quá chiều cao */
    //     animation: onClickIconDropMenuLi 0.5s forwards;
    // }
    // .select-service {
    //     display: flex !important;
    //     flex-direction: column;
    //     gap: 30px;
    //     justify-content: center;
    //     li{
    //         list-style: none;
    //         padding:0 10px;
    //     }
    //     margin:0;
    //     overflow: hidden; /* Ẩn nội dung vượt quá chiều cao */
    //     animation: onClickIconDropMenuLiService 0.5s forwards;
    // }

    // @keyframes onClickIconDropMenuLiService {
    //     from {
    //         height: 0;
    //     }
    //     to {
    //         height: 200px; /* Đặt giá trị cố định */
    //     }
    // }
    // @keyframes onClickIconDropMenuLi {
    //     from {
    //         height: 0;
    //     }
    //     to {
    //         height: 500px; /* Đặt giá trị cố định */
    //     }
    // }

    // .back-select-service {
    //     display: flex !important;
    //     flex-direction: column;
    //     gap: 30px;
    //     margin:0;
    //     justify-content: center;
    //     li{
    //         list-style: none;
    //         padding:0 10px;
    //     }
    //     overflow: hidden; /* Ẩn nội dung vượt quá chiều cao */
    //     animation: onClickBackIconDropMenuLiService 0.2s forwards;
    // }
    // .back-select-library {
    //     display: flex !important;
    //     flex-direction: column;
    //     gap: 30px;
    //     justify-content: center;
    //     li{
    //         list-style: none;
    //         padding:0 10px;
    //     }
    //     margin:0;
    //     overflow: hidden;/* Ẩn nội dung vượt quá chiều cao */
    //     animation: onClickBackIconDropMenuLi 0.2s forwards;
    // }
    // @keyframes onClickBackIconDropMenuLiService {
    //     from {
    //         height: 200px;
    //     }
    //     to {
    //         height: 0; /* Đặt giá trị cố định */
    //     }
    // }
    // @keyframes onClickBackIconDropMenuLi {
    //     from {
    //         height: 500px;
    //     }
    //     to {
    //         height: 0; /* Đặt giá trị cố định */
    //     }
    // }
    .sub-menu-homepage {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        opacity: 0;
        max-height: 0; /* Giới hạn chiều cao ban đầu */
        transition:
            max-height 0.5s ease,
            opacity 0.5s ease;
        li {
            list-style-type: none;
            padding: 5px 17px;
            font-weight: 600;
        }
        a {
            text-decoration: none;
            background-color: unset;
            color: white;
            border: none;
            padding: 5px 10px;
            font-weight: 600;
            width: 100%;
            display: flex;
            justify-content: space-between;
            &:hover {
                color: #50adff;
            }
        }
    }
    .open-homepage {
        opacity: 1; /* Hiển thị khi mở */
        max-height: 500px; /* Chiều cao tối đa cho menu khi mở */
        overflow-y: auto;
    }
    .select-menu {
        display: flex !important;
        z-index: 10;
        position: fixed;
        background: rgba(0, 0, 0, 0.929);
        padding: 100px 20px;
        width: 100%;
        height: 100vh;
        transition: all 0.5s;
        animation: onClickMenu 0.5s forwards;
        @keyframes onClickMenu {
            from {
                transform: translateX(-100vh);
            }
            to {
                transform: translateX(0);
            }
        }
        .nav-mobile {
            width: 100%;
            padding: 20px;
            color: white;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 20px;

            a {
                display: flex;
                align-items: center;
                gap: 20px;
                &:hover {
                    color: #50adff;
                }
                img {
                    width: 20px;
                }
                li {
                    font-size: 1rem;
                }
                text-decoration: none;
                color: white;
            }
            .nav-ser,
            .nav-lib {
                cursor: pointer;
                &:hover {
                    color: #50adff;
                }
            }

            .rotate-icon-menu {
                animation: onClickIconDropMenu 0.5s forwards;
            }

            .rotate-icon-menu-reverse {
                animation: onClickIconDropMenuReverse 0.5s forwards;
            }

            @keyframes onClickIconDropMenu {
                from {
                    rotate: 0deg;
                }
                to {
                    rotate: 90deg;
                }
            }

            @keyframes onClickIconDropMenuReverse {
                from {
                    rotate: 90deg;
                }
                to {
                    rotate: 0deg;
                }
            }
        }
    }
    .close-menu {
        display: flex !important;
        z-index: 10;
        position: fixed;
        background: rgba(0, 0, 0, 0.929);
        padding: 100px 20px;
        width: 100%;
        height: 100vh;
        transition: all 0.5s;
        animation: onClickMenu1 0.5s forwards;
        @keyframes onClickMenu1 {
            from {
                transform: translateX(0vh);
            }
            to {
                transform: translateX(-100vh);
            }
        }
        .nav-mobile {
            width: 100%;
            padding: 20px;
            color: white;
            font-size: 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 20px;

            a {
                display: flex;
                align-items: center;
                gap: 20px;
                &:hover {
                    color: #50adff;
                }
                img {
                    width: 20px;
                }
                li {
                    font-size: 1rem;
                }
                text-decoration: none;
                color: white;
            }
            .nav-ser,
            .nav-lib {
                cursor: pointer;
                &:hover {
                    color: #50adff;
                }
            }

            .rotate-icon-menu {
                animation: onClickIconDropMenu 0.5s forwards;
            }

            .rotate-icon-menu-reverse {
                animation: onClickIconDropMenuReverse 0.5s forwards;
            }

            @keyframes onClickIconDropMenu {
                from {
                    rotate: 0deg;
                }
                to {
                    rotate: 90deg;
                }
            }

            @keyframes onClickIconDropMenuReverse {
                from {
                    rotate: 90deg;
                }
                to {
                    rotate: 0deg;
                }
            }
        }
    }
    .mobile-header {
        margin: 0;
        display: flex;
        position: absolute;
        width: 100%;
        z-index: 100;
        color: white;
        padding: 5px;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.871);
        box-shadow: 2px -3px 18px #888888;
        transition: all 0.5s;
        .links {
            a {
                color: white;
            }
        }
        .logo-mobile {
            text-align: center;
            img {
                width: 80px;
            }
        }
        .icon-menu {
            width: 100px;
            font-size: 1.5rem;
            // padding:0;
            #checkbox2 {
                display: none;
                &.checked + .toggle2 .bars {
                    position: absolute;
                    transition-duration: 0.5s;
                }
                &.checked + .toggle2 #bar5 {
                    transform: scaleX(0);
                    transition-duration: 0.5s;
                }
                &.checked + .toggle2 #bar4 {
                    width: 100%;
                    transform: rotate(45deg);
                    transition-duration: 0.5s;
                }
                &.checked + .toggle2 #bar6 {
                    width: 100%;
                    transform: rotate(-45deg);
                    transition-duration: 0.5s;
                }
                &.checked + .toggle2 {
                    transition-duration: 0.5s;
                    transform: rotate(180deg);
                }
            }

            .toggle2 {
                position: relative;
                width: 25px;
                height: 25px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 5px;
                transition-duration: 0.5s;
                margin: 0;
                .bars {
                    width: 100%;
                    height: 3px;
                    background-color: rgb(255, 255, 255);
                    border-radius: 6px;
                }

                #bar5 {
                    transition-duration: 0.8s;
                }

                #bar4,
                #bar6 {
                    width: 80%;
                }
            }
        }
        .hotline {
            display: flex;
            flex-direction: row-reverse;
            padding: 0;
            .ele {
                background: #50adff;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1px;
                width: 130px;
                border-radius: 10px;
                padding: 5px 0;
                text-align: center;
                font-size: 13px;
            }
        }
    }
}
.hotline-icon-one-pc {
    z-index: 999;
    width: max-content;
    background: #50adffb8;
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    .hotline-icon-svg {
        width: 35px;
        // animation: rotate 1.5s infinite;
    }
    position: fixed;
    bottom: 50px;
    right: 40px;
}
.hotline-icon-one-ios {
    z-index: 999;
    width: max-content;
    background: #50adffb8;
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    .hotline-icon-svg {
        width: 35px;
        // animation: rotate 1.5s infinite;
    }
    position: fixed;
    bottom: 50px;
    right: 40px;
    display: none;
}
.hotline-icon-one-android {
    z-index: 999;
    width: max-content;
    background: #50adffb8;
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    .hotline-icon-svg {
        width: 35px;
        // animation: rotate 1.5s infinite;
    }
    position: fixed;
    bottom: 50px;
    right: 40px;
    display: none;
}
.hotline-icon-two {
    z-index: 999;
    width: max-content;
    background: #50adffb8;
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    .hotline-icon-svg {
        width: 35px;
        // animation: rotate 1.5s infinite;
    }
    position: fixed;
    bottom: 135px;
    right: 40px;
}
@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}
@media only screen and (max-width: 600px) {
    .hotline-icon-one-pc {
        display: flex;
    }
    .hotline-icon-one-ios {
        display: flex;
    }
    .hotline-icon-one-android {
        display: flex;
    }
}
