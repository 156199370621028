.blog-kld1901 {
    font-family: "Play", serif;
    .section-1-kld1901 {
        margin: 0 auto;
        display: flex;
        padding: 150px 0;
        width: 1400px;
        .left {
            padding-right:20px;
            display:flex;
            flex-direction: column;
            gap:20px;
            .ed-name{
                border-top:1px solid rgb(218, 218, 218);
                padding:20px 0;
            }
            .ed-category{
                display:flex;
                gap:20px;
                p{
                    color:#af8647;
                    border: 1px solid #af8647;
                    border-radius: 15px;
                    padding:10px 20px;
                    transition: all 0.5s;
                    cursor: pointer;
                    &:hover{
                        color:white;
                        background-color: #af8647;
                    }
                }
            }
            img{
                border-radius: 15px;
                width: 100%;
                height: 400px;
                object-fit: cover;
            }
            h1{
                color:#af8647;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1px;
                text-align: center;
            }
            h5{
                color:#af8647;
                font-weight: bold;
                letter-spacing: 1px;
            }
            p{
                letter-spacing: 1px;
            }
            li{
                padding:0 20px;
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            gap: 40px;
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                text-transform: uppercase;
                font-weight: bold;
                color: #4a4a4a;
                &::before,
                &::after {
                    content: "";
                    flex: 1;
                    height: 1px;
                    background-color: #4a4a4a;
                    margin: 0 10px;
                }
            }
            .bxs {
                width: calc(100% - 20px);
                margin: 0 auto;
                input {
                    width: 85%;
                    border-radius: 13px 0 0 13px;
                    padding: 10px;
                    border: 1px solid grey;
                }
                button {
                    i {
                        color: white;
                    }
                    width: 15%;
                    background-color: #af8647;
                    border-radius: 0 13px 13px 0;
                    border: 1px solid #af8647;
                    padding: 10px;
                }
            }
            .bxfr {
                width: calc(100% - 20px);
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .text {
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                    padding: 10px 0;
                    // border-bottom: 1px solid black;
                    transition: all 0.5s;
                    &:hover {
                        color: #af8647;
                    }
                }
            }
        }
    }
}
