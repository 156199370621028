.sell-website-product-detail {
    color: white;
    .banner-image {
        margin: 0;
        padding: 100px 150px;
        align-items: center;
        img {
            animation: bounce 2s infinite ease-in-out;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .content {
            background: rgba(10, 10, 10, 0.5);
            border-radius: 15px;
            .box {
                padding: 50px 100px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                .title {
                    font-size: 2rem;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: bold;
                }
                .sub-title {
                    font-size: 1rem;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
                .text {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    i {
                        color: grey;
                    }
                    .text-left {
                        color: grey;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    .span-box {
                        display: flex;
                        gap: 10px;
                        .span-ele {
                            i {
                                padding-right: 10px;
                            }
                        }
                    }
                }
                .btn-box {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding: 20px 0;
                    .btn-left,
                    .btn-right {
                        text-decoration: none;
                        color: white;
                        cursor: pointer;
                        padding: 10px 20px;
                        border-radius: 15px;
                        border: 1px solid #50adff;
                        text-transform: uppercase;
                        transition:
                            background-color 0.5s ease,
                            color 0.5s ease;
                        &:hover {
                            transition:
                                background-color 0.5s ease,
                                color 0.5s ease;
                            background-color: #50adff;
                            color: white;
                        }
                    }
                }
                .price {
                    font-size: 2rem;
                    color: #50adff;
                    letter-spacing: 2px;
                }
            }
        }
        @keyframes bounce {
            0%,
            100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-20px);
            }
        }
    }
    .title-process-step {
        padding: 50px 0;
        width: 1200px;
        margin: 0 auto;
        .sub-title {
            margin: 0 auto;
            text-align: center;
            color: grey;
            font-size: 18px;
        }
        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .btn-process-step {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        padding: 20px 0 100px 0;
        .title {
            text-decoration: none;
            color: white;
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: #50adff;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: #50adff;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .sell-website-process-work {
        padding: 50px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        .step-process {
            padding: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            cursor: pointer;
            &:hover {
                .title {
                    background: #50adff;
                }
            }
            &:not(:last-child):after {
                content: "";
                position: absolute;
                top: 25%;
                right: -25px;
                width: 50px;
                height: 2px;
                background-color: #50adff;
                transform: translateY(-50%);
            }
            &:not(:last-child):before {
                content: "";
                position: absolute;
                top: 25%;
                right: -40px;
                border: solid transparent;
                border-left-color: #50adff;
                border-width: 10px;
                transform: translateY(-50%);
            }
            .title {
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: 2px;
                border-radius: 50%;
                border: 1px solid #50adff;
                width: max-content;
                padding: 10px 15px;
                position: relative;
            }
            .sub-title {
                color: #50adff;
                padding: 10px 0;
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }
    .accordion-box {
        width: 100%;
        .accordion {
            padding: 50px 0;
            width: 1200px;
            margin: 0 auto;
            background-color: unset !important;
            color: white;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            .accordion-item {
                margin-bottom: 20px;
                background-color: unset !important;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
                .accordion-header {
                    .accordion-button {
                        color: white;
                        font-size: 1.5rem;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        box-shadow: none;
                        background-color: unset !important;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                        &:after {
                            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
                            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
                        }
                    }
                }
                .accordion-collapse {
                    .accordion-body {
                        margin-bottom: 20px;
                        color: white;
                        padding: 30px 20px;
                        border-radius: 15px;
                        background: rgba(10, 10, 10, 0.5);
                        .box-text {
                            padding: 10px 0;
                            font-size: 1.1rem;
                            letter-spacing: 1px;
                            .title {
                                color: #50adff;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
    .part-product {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .sub-title {
            margin: 0 auto;
            text-align: center;
            color: grey;
            font-size: 18px;
        }
        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2rem;
            font-weight: bold;
            text-transform: uppercase;
        }
        .list-product {
            // margin: 0;
            // .nav-link {
            //     text-align: center;
            //     padding: 15px;
            //     display: flex;
            //     justify-content: center;
            //     flex-direction: column;
            //     .product {
            //         border: 1px solid #50adff;
            //         // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            //         border-radius: 15px;
            //         overflow: hidden;
            //         background-color: #212121ac;
            //         color: white;
            //         .image-container {
            //             width: 100%;
            //             height: 400px;
            //             overflow: hidden;
            //             position: relative;

            //             img {
            //                 width: 100%;
            //                 position: relative;
            //                 top: 0;
            //                 transition: top 5s ease;
            //             }
            //             &:hover img {
            //                 top: -100%;
            //             }
            //         }
            //         .product-name {
            //             text-transform: uppercase;
            //             font-size: 18px;
            //             padding: 15px 5px;
            //             font-weight: bold;
            //         }
            //         .product-view {
            //             text-transform: uppercase;
            //             padding: 0 5px 15px 5px;
            //         }
            //         .product-price {
            //             padding: 15px 0;
            //             border-top: 1px solid #50adff;
            //             color: white;
            //             font-size: 20px;
            //             transition:
            //                 box-shadow 0.5s ease-in-out,
            //                 color 0.5s ease-in-out;
            //         }
            //         &:hover {
            //             .product-price {
            //                 box-shadow: inset 0 0 1.5em 1.5em #50adff;
            //                 color: white !important;
            //             }
            //         }
            //     }
            // }
            .nav-link {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 50px;
                transition: all 0.2s ease-in-out;
                .image-container {
                    img {
                        width: 100%;
                        filter: drop-shadow(rgb(255, 255, 255) 0px 0px 0.55rem);
                    }
                }
                .product-name {
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    padding: 10px 5px;
                    font-weight: bold;
                }
                .product-price {
                    padding: 10px 0;
                    color: #50adff;
                    font-size: 1.3rem;
                    transition:
                        box-shadow 0.5s ease-in-out,
                        color 0.5s ease-in-out;
                }
                .btn-show {
                    cursor: pointer;
                    position: relative;
                    width: max-content;
                    overflow: hidden;
                    padding: 10px 0;
                    font-size: 1rem;
                    letter-spacing: 1px;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: #50adff;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        transition: all 0.2s ease;
                        color: #50adff;

                        &::after {
                            width: 0%;
                        }
                    }
                }
                &:hover {
                    transition: all 0.2s ease-in-out;
                    transform: scale(1.1);
                }
            }
        }
    }
    .type-website {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .content {
            display: flex;
            justify-content: center;

            ul {
                li {
                    cursor: pointer;

                    padding: 5px 0;
                    a {
                        text-decoration: none;
                        color: white;
                        &:hover {
                            color: #3a87cb;
                        }
                    }
                    .active {
                        color: #50adff;
                    }
                }
            }
        }
    }
    .animate-kassdev-bottom-5s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-bottom-4s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-bottom-3s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-bottom-2s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-bottom-1s {
        opacity: 0;
        transform: translateY(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-top-5s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-top-2s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-top-4s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-top-3s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-top-1s {
        opacity: 0;
        transform: translateY(-100px);
        transition: all 1s ease;
    }
    .animate-kassdev-right-5s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 5s ease;
    }
    .animate-kassdev-right-4s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 4s ease;
    }
    .animate-kassdev-right-3s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 3s ease;
    }
    .animate-kassdev-right-2s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 2s ease;
    }
    .animate-kassdev-right-1s {
        opacity: 0;
        transform: translateX(100px);
        transition: all 1s ease;
    }
    .animate-kassdev-left-5s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 5s ease;
    }
    .animate-kassdev-left-3s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 3s ease;
    }
    .animate-kassdev-left-4s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 4s ease;
    }
    .animate-kassdev-left-2s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 2s ease;
    }
    .animate-kassdev-left-1s {
        opacity: 0;
        transform: translateX(-100px);
        transition: all 1s ease;
    }

    .animate-kassdev-show {
        opacity: 1;
        transform: translateY(0);
        transform: translateX(0);
    }
}
@media (max-width: 950px) {
    .sell-website-product-detail {
        .banner-image {
            padding: 150px 10px 100px 10px;
            .image {
                text-align: center;
                img {
                    width: 350px;
                    height: 250px;
                }
            }
            .content {
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                .box {
                    padding: 30px;
                }
                .text {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    font-size: 1rem;
                    gap: 10px;
                }
                .btn-box {
                    span {
                        width: 100%;
                        a {
                            width: 100%;
                        }
                        margin: 10px 0;
                    }
                    display: flex;
                    flex-flow: column;
                }
            }
        }
        .title-process-step {
            width: 100%;
            padding: 50px 20px;
        }
        .sell-website-process-work{
            flex-direction: column;
            width: 100%;
            padding:0 20px;
            gap:100px;
            .step-process {
                width: 100%;
                &:not(:last-child):after {
                    top: 125%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                &:not(:last-child):before {
                    top: 145%;
                    right: 45%;
                    width: 50px;
                    transform: rotate(90deg);
                }
                .title {
                    font-size: 2rem;
                    font-weight: bold;
                    letter-spacing: 2px;
                    border-radius: 50%;
                    border: 1px solid #50adff;
                    width: max-content;
                    padding: 10px 15px;
                    position: relative;
                }
                .sub-title {
                    color: #50adff;
                    padding: 10px 0;
                    font-size: 1.5rem;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
        .btn-process-step{
            width: 100%;
        }
        .accordion-box {
            width: 100%;
            .accordion {
                width: 100%;
                .accordion-item {
                    .accordion-header {
                        .accordion-button {
                        }
                    }
                }
            }
        }

        .part-product {
            width: 100%;
            padding: 20px;
            .title {
                font-size: 1.4rem;
            }
        }
        .type-website .content {
            justify-content: normal;
            ul {
                margin: 0;
            }
        }
        .animate-kassdev-right-1s,
        .animate-kassdev-left-1s,
        .animate-kassdev-bottom-1s,
        .animate-kassdev-top-1s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 1s ease;
        }
        .animate-kassdev-right-3s,
        .animate-kassdev-left-3s,
        .animate-kassdev-bottom-3s,
        .animate-kassdev-top-3s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 3s ease;
        }
        .animate-kassdev-right-4s,
        .animate-kassdev-left-4s,
        .animate-kassdev-bottom-4s,
        .animate-kassdev-top-4s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 4s ease;
        }
        .animate-kassdev-right-5s,
        .animate-kassdev-left-5s,
        .animate-kassdev-bottom-5s,
        .animate-kassdev-top-5s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 5s ease;
        }
        .animate-kassdev-right-2s,
        .animate-kassdev-left-2s,
        .animate-kassdev-bottom-2s,
        .animate-kassdev-top-2s {
            opacity: 0;
            transform: translateX(-50px) !important;
            transition: all 2s ease;
        }
        .animate-kassdev-show {
            opacity: 1;
            transform: translateX(0) !important;
        }
    }
}
