.about-kld2812 {
    .section-1-kld2812 {
        width: 100vw;
        height: 100vh;
        transition: all 1.4s;
        video {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            z-index: 6;
            mix-blend-mode: exclusion;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            height: 95vh;
            padding-bottom: 0;
            padding-left: 2em;
            padding-right: 2em;
            display: flex;
            position: absolute;
            overflow: hidden;
            .title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Dancing Script", serif;
                font-size: 12.2vw;
                font-weight: 300;
                line-height: 1em;
            }
            .sub-title {
                color: #f6f6f2;
                text-transform: uppercase;
                mix-blend-mode: difference;
                align-self: center;
                width: auto;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0.2em;
                font-family: "Roboto", serif;
                font-size: 1.2rem;
                font-weight: 300;
                line-height: 1em;
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
                    skew(0deg, 0deg);
                opacity: 1;
                transform-style: preserve-3d;
            }
        }
    }
    .section-2-kld2812 {
        width: 100vw;
        margin: 0 auto;
        padding: 100px 0;
        color: black;
        .title {
            margin: 0 auto;
            font-size: 4rem;
            width: 600px;
            font-family: "Dancing Script", serif;
            padding: 50px 0;
        }
        .text {
            font-size: 1.2rem;
            padding: 10px 0;
            width: 600px;
            margin: 0 auto;
            &:nth-last-child(1) {
                padding-bottom: 50px;
            }
        }
        .images {
            width: 600px;
            height: max-content;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            img {
                overflow: hidden;
                width: 100%;
                height: 800px;
                object-fit: cover;
                transform-origin: center;
                transition: transform 0.3s ease;
            }
        }
    }
    .section-3-kld2812 {
        width: 100vw;
        margin: 0 auto;
        padding: 0 0 100px 0;
        color: black;
        .title {
            margin: 0 auto;
            font-size: 4rem;
            width: 600px;
            font-family: "Dancing Script", serif;
            padding: 50px 0;
        }
        .text {
            font-family: "Roboto", sans-serif;
            font-size: 1.2rem;
            display: flex;
            letter-spacing: 2px;
            padding: 10px 0;
            width: 600px;
            margin: 0 auto;
        }
    }
    .section-4-kld2812 {
        display: flex;
        flex-direction: column; /* Sắp xếp các text-box theo cột (dọc) */
        width: 100vw;
        height: max-content;
        padding: 50px; /* Khoảng cách xung quanh container */
        color:black;
        .text-box-left {
            display: flex;
            justify-content: flex-start; /* Căn nội dung sang trái */
            width: 100%;
            padding: 20px 0;
        }
    
        .text-box-center {
            display: flex;
            justify-content: center; /* Căn nội dung vào giữa */
            width: 100%;
            padding: 20px 0;
        }
    
        .text-box-right {
            display: flex;
            justify-content: flex-end; /* Căn nội dung sang phải */
            width: 100%;
            padding: 20px 0;
        }
        .left{
            font-family: "Dancing Script", serif;
            font-size: 1.7rem;
            letter-spacing: 1px;

        }
        .right{
            font-family: 'Roboto', sans-serif;
            font-size: 1.1rem;
            letter-spacing: 1px;
            display: flex;
            flex-direction: column;
            gap:20px;
            .text{
                font-family: 'Roboto', sans-serif;
                letter-spacing: 1px;
                font-size: 1.1rem;
            }
        }
        .left, .right {
            padding:5px;
            width: 250px; /* Tự động co giãn theo nội dung */
        }
    }
    
    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .about-kld2812 {
        .section-1-kld2812{
            width: 100%;
            height: 400px;
            video{
                height: 400px;
            }
            .introduce {
                height: 400px;
                justify-content: center
            }
        }
        .section-2-kld2812{
            width: 100%;
            padding:20px;
            .title{
                width: 100%;
                font-size: 2rem;
            }
            .text{
                width: 100%;
                font-size: 1rem;
            }
            .images{
                width: 100%;
                height: 500px;
                object-fit: cover;
            }
        }
        .section-3-kld2812{
            width: 100%;
            padding:20px;
            .title{
                width: 100%;
                font-size: 2rem;
            }
            .text{
                width: 100%;
                font-size: 1rem;
            }
        }
        .section-4-kld2812{
            width: 100%;
        }
        .section-11-kld2812 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                font-size: 2rem;
            }
            .box-sent {
                .text {
                    font-size: 1rem;
                }
                .input-text {
                    font-size: 1rem;
                }
            }
            .btn-sent {
                font-size: 1.5rem;
            }
        }
    }
}
