.fifth-layout-kld1812 {
    font-family: "Jost", sans-serif;
    .section-1-kld1812 {
        padding: 150px 0 100px 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        .right {
            order: 1;
            padding: 0;
            img {
                border-radius: 15px;
                width: 100%;
                object-fit: cover;
                height: 500px;
            }
        }
        .left {
            order: 2;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                object-fit: cover;
                height: 200px;
            }
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 10px 30px;
            }
            .sub-title {
                font-size: 1.3rem;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #ca8126;
                cursor: pointer;
                padding: 0 30px 20px 30px
            }
            .text {
                font-size: 1.1rem;
                letter-spacing: 1px;
                padding: 0 30px 20px 30px
            }
        }
    }
    .section-2-kld1812 {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        padding:0 0 100px 0;
        .right {
            order: 2;
            padding: 0;
            img {
                border-radius: 15px;
                width: 100%;
                object-fit: cover;
                height: 500px;
            }
        }
        .left {
            order: 1;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;

            img {
                object-fit: cover;
                height: 200px;
            }
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 10px 30px;
            }
            .sub-title {
                font-size: 1.3rem;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #ca8126;
                cursor: pointer;
                padding: 0 30px 20px 30px
            }
            .text {
                font-size: 1.1rem;
                letter-spacing: 1px;
                padding: 0 30px 20px 30px
            }
        }
    }
    .section-3-kld1812 {
        padding: 0 0 150px 0;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        .right {
            order: 1;
            padding: 0;
            img {
                border-radius: 15px;
                width: 100%;
                object-fit: cover;
                height: 500px;
            }
        }
        .left {
            order: 2;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;

            img {
                object-fit: cover;
                height: 200px;
            }
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 10px 30px;
            }
            .sub-title {
                font-size: 1.3rem;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: #ca8126;
                cursor: pointer;
                padding: 0 30px 20px 30px
            }
            .text {
                font-size: 1.1rem;
                letter-spacing: 1px;
                padding: 0 30px 20px 30px
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.5s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .fifth-layout-kld1812 {
        .section-1-kld1812 {
            padding: 120px 0 0 0;
            width: 100%;
            flex-direction: column;
            .right {
                order: 1;
                img {
                    border-radius: 0px;
                    height: 400px;
                }
            }
            .left {
                border-radius: 0;
                order: 2;
                padding:30px 0;
            }
        }
        .section-2-kld1812 {
            padding: 50px 0 0 0;
            width: 100%;
            flex-direction: column;
            .right {
                order: 1;
                img {
                    border-radius: 0px;
                    height: 400px;
                }
            }
            .left {
                border-radius: 0;
                order: 2;
                padding:30px 0;
            }
        }
        .section-3-kld1812 {
            padding: 50px 0 50px 0;
            width: 100%;
            flex-direction: column;
            .right {
                order: 1;
                img {
                    border-radius: 0px;
                    height: 400px;
                }
            }
            .left {
                border-radius: 0;
                order: 2;
                padding:30px 0;
            }
        }
    }
}
