.portfolio-kld2812 {
    font-family: "Roboto", serif;
    .section-1-kld2812 {
        width: 100vw;
        height: 100vh;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100vh;
            width: 100%;
            color: black;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding: 50px 150px;
            .title {
                font-family: "Dancing Script", serif;
                line-height: 25rem;
                text-align: center;
                font-size: 20rem;
                letter-spacing: 2px;
            }
            .text {
                font-family: "Roboto", serif;
                
                text-transform: uppercase;
                text-align: center;
                font-size: 1rem;
                letter-spacing: 1px;
                font-weight: bold;
            }
        }
    }
    .section-2-kld2812{
        width: 1000px;
        margin:0 auto;
        padding:50px 0;
        color:black;
        .title{
            font-family: 'Dancing Script', serif;
            font-size:2.5rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: center;
        }
    }
    .section-8-kld2812 {
        width: 100%;
        display: flex;
        margin: 0 auto;
        padding:50px 0;
        .col-3 {
            display: flex;
            gap: 10px;
            flex-direction: column;
            img {
                padding: 0 10px;
                width: 100%;
                height: 600px;
                object-fit: contain;
            }
        }
        .col-3-2{
            padding-top: 300px;
        }
        .col-3-3{
            padding-top: 150px;
        }
    }
    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }

    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .portfolio-kld2812{
        .section-1-kld2812{
            width: 100%;
            height: 500px;
            img{
                height: 500px;
            }
            .introduce{
                width: 100%;
                height: 500px;
                padding:20px;
                .title{
                    width: 100%;
                    font-size: 4rem;
                    line-height: unset;
                }
                .text{
                    width: 100%;
                    font-size: 0.8rem;
                }
            }
        }
        .section-2-kld2812{
            width: 100%;
            padding: 50px 20px;
            .title{
                font-size: 2rem;
            }
        }
        .section-8-kld2812{
            width: 100%;
            .col-3{
                img{
                    height: 150px;
                }
            }
            .col-3-2{
                padding-top: 150px;
            }
            .col-3-3{
                padding-top: 75px;
            }
        }
        .section-11-kld2812{
            width: 100%;
            padding:20px;
            .title{
                width: 100%;
                font-size: 2rem;
            }
            .box-sent{
                .text{
                    font-size: 1rem;
                }
                .input-text{
                    font-size: 1rem;
                }
            }
            .btn-sent{
                font-size: 1.5rem;
            }
        }
    }
}