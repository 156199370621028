.homepage-kld2112 {
    font-family: "Montserrat", serif;
    background-color: black;
    .section-1-kld2112 {
        width: 100vw;
        height: 100vh;
        transition: all 1.4s;
        video {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.305);
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 150px;
            .title {
                width: 500px;
                font-size: 3.5rem;
                letter-spacing: 2px;
            }
            .sub-title {
                width: 500px;
                font-size: 1.1rem;
                letter-spacing: 1px;
                padding: 20px 0;
            }
            .btn-intro {
                display: flex;
                gap: 20px;
                .btn-ele {
                    width: 200px;
                    border: 2px solid white;
                    transition:
                        box-shadow 0.2s ease-in-out,
                        color 0.5s ease-in-out;
                    text-align: center;
                    padding: 10px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        color: #af8647;
                        box-shadow: inset 0 0 1.5em 1.5em #fff;
                    }
                }
            }
        }
    }
    .section-2-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        .title {
            text-align: center;
            font-size: 2.5rem;
            padding: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .test {
            display: flex;

            align-items: center;

            img {
                height: 105px;
                width: 250px;
                object-fit: contain;
                padding: 20px 40px;
                border-right: 1px solid #af8647;
            }
        }
    }
    .section-3-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 50px 0;
        .title {
            margin: 0 auto;
            font-size: 2.5rem;
            text-align: center;
            letter-spacing: 2px;
            padding: 50px;
            text-transform: uppercase;
        }
        .section-3-1 {
            display: flex;
            text-align: center;
            .col-md-4 {
                padding: 10px;
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 300px;
                }
                .title {
                    font-size: 1.5rem;
                    padding: 30px;
                    height: 100px;
                }
                .sub-title {
                    padding: 30px;
                    height: 200px;
                }
                .btn-link {
                    background-color: unset;
                    font-size: 1rem;
                    color: white;
                    text-decoration: none;
                    border: 2px solid white;
                    transition:
                        box-shadow 0.2s ease-in-out,
                        color 0.5s ease-in-out;
                    text-align: center;
                    padding: 15px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        color: #af8647;
                        box-shadow: inset 0 0 1.5em 1.5em #fff;
                    }
                }
            }
        }
    }
    .section-4-kld2112 {
        width: 1300px;
        margin: 0 auto;

        .title-section {
            text-align: center;
            font-size: 2.5rem;
            padding: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .slider-section-4 {
            padding-bottom: 100px;
            .section-4-1 {
                width: 45%;
                cursor: pointer;
                .image {
                    margin: 20px;
                    overflow: hidden;
                    border-radius: 15px;
                    img {
                        border-radius: 15px;
                        object-fit: cover;
                        height: 350px;
                        width: 100%;
                        border-radius: 15px;
                        border: none;
                        transition: all 0.2s;
                        &:hover {
                            overflow: hidden;
                            border-radius: 15px;
                            transform: scale(1.21);
                        }
                    }
                }
                .title {
                    font-size: 2rem;
                    letter-spacing: 2px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 20px;
                }
                .sub-title {
                    font-size: 1rem;
                    font-weight: bold;
                    padding: 0 20px;
                }
            }

            .btn-prev-next {
                transition: all 0.2s;
                &:hover {
                    background-color: #af8647;
                    .fa-chevron-left,
                    .fa-chevron-right {
                        color: white !important;
                    }
                }
            }
        }
    }
    .section-5-kld2112 {
        --sectionBackground: linear-gradient(rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.57)),
            url(https://res.cloudinary.com/dolydpat4/image/upload/v1734809442/element-bg2-1_exrewp.webp);
        background-image: none;
        transition: all 0.5s;
        clip-path: polygon(0px -2px, 100% -2px, 100% 102%, 0% 102%);
        .testimonials-section:before {
            display: block;
            content: "";
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: var(--sectionBackground) center no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            position: fixed;
            z-index: -1;
            pointer-events: none;
        }
        .testimonials-section {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 900px;
            height: 700px;
            margin: 0 auto;
            padding: 150px 0;
            gap: 30px;
            transition: max-height 0.5s;
            .title {
                font-size: 1.4rem;
                padding-bottom: 30px;
                letter-spacing: 3px;
                transition: all 0.5s;
            }
            .text {
                padding-bottom: 30px;
                font-size: 15px;
                line-height: 25px;
                max-height: 500px;
                transition: max-height 0.5s ease;
                transition: all;
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }
    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
    .fade-out {
        opacity: 0;
        width: 0;
        height: 0;
        visibility: hidden; /* Ẩn nội dung hoàn toàn sau khi hiệu ứng chạy xong */
    }
}
@media (max-width: 950px) {
    .homepage-kld2112 {
        .section-1-kld2112 {
            width: 100%;
            height: 400px !important;
            video {
                height: 400px;
            }
            .introduce {
                height: 400px;
                padding: 60px 20px;
                justify-content: flex-end;
                .title {
                    width: 100%;
                    font-size: 1.7rem;
                }
                .sub-title {
                    width: 100%;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                .btn-intro {
                    .btn-ele {
                        width: 150px;
                        font-size: 13px;
                        border:1px solid white;
                    }
                }
            }
        }
        .section-2-kld2112 {
            width: 100%;
            .title {
                font-size: 1.5rem;
            }
            .test {
                img {
                    height: 90px;
                    padding: 10px 20px;
                    }
            }
        }
        .section-3-kld2112 {
            width: 100%;
            .title {
                font-size: 1.5rem;
            }
            .section-3-1 {
                display: flex;
                text-align: center;
                flex-direction: column;
                .col-md-4 {
                    width: 100%;
                    padding: 20px;
                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 300px;
                        border-radius: 15px;
                    }
                    .title {
                        font-size: 1.5rem;
                        height: max-content;
                    }
                    .sub-title {
                        padding: 0 10px 20px 10px;
                        height: max-content;
                    }
                }
            }
        }
        .section-4-kld2112 {
            width: 100%;
            .title-section {
                font-size: 1.5rem;
            }
            .slider-section-4 {
                padding-bottom: 100px;
                .btn-prev-next{
                    display: none;
                }
                .section-4-1 {
                    width: 100%;
                    cursor: pointer;
                    
                    .image {
                        margin: 20px;
                        overflow: hidden;
                        border-radius: 15px;
                        img {
                            height: 300px;
                        }
                    }
                    .title {
                        text-align: center;
                        font-size: 1.5rem;
                        padding: 0 20px;
                    }
                    .sub-title {
                        text-align: center;
                        font-size: 1rem;
                        font-weight: normal;
                        padding: 10px 20px;
                    }
                }
    
                .btn-prev-next {
                    transition: all 0.2s;
                    &:hover {
                        background-color: #af8647;
                        .fa-chevron-left,
                        .fa-chevron-right {
                            color: white !important;
                        }
                    }
                }
            }
        }
        .section-5-kld2112 {
            width: 100%;
            .testimonials-section {
                width: 100%;
                height: 650px;
                flex-direction: column;
                .col-md-3{
                    svg{
                        padding:20px;
                        width: 150px;
                        height: 150px;
                    }
                }
                .col-md-9{
                    width: 100%;
                    padding:20px;
                }
                .title {
                    font-size: 1.3rem;
                }
                .text {
                    padding-bottom: 20px;
                    font-size: 13px;
                }
                .sub-title{
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
