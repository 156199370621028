.first-layout-kld2112 {
    font-family: "Montserrat", serif;
    background-color: black;
    color: white;
    .section-1-kld2112 {
        width: 100vw;
        height: 100vh;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.398);
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 150px;
            .title {
                font-family: "Playfair Display", serif !important;
                width: 1300px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 4rem;
                letter-spacing: 2px;
            }
            .sub-title {
                font-family: "Playfair Display", serif;
                width: 1300px;
                text-transform: uppercase;
                text-align: center;
                background: -webkit-linear-gradient(#eb9d29, #cbcad6);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 1.3rem;
                letter-spacing: 1px;
                padding: 20px 0;
            }
            .btn-intro {
                display: flex;
                gap: 20px;
                .btn-ele {
                    width: 200px;
                    border: 2px solid white;
                    transition:
                        box-shadow 0.2s ease-in-out,
                        color 0.5s ease-in-out;
                    text-align: center;
                    padding: 10px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: 600;
                    &:hover {
                        color: #af8647;
                        box-shadow: inset 0 0 1.5em 1.5em #fff;
                    }
                }
            }
        }
    }
    .section-2-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 100px 0 50px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .title {
            text-align: center;
            font-size: 2.5rem;
            padding: 0 50px 50px 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .input-search {
            width: 100%;
            padding: 20px;
            background-color: unset;
            color: white;
            font-weight: bold;
            border: none;
            border-bottom: 1px solid #af8647;
            letter-spacing: 1px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
        .form-group {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            .select-div {
                position: relative;
                display: inline-block;
                .custom-select {
                    width: 100%;
                    padding: 20px;
                    background-color: unset;
                    color: white;
                    font-weight: bold;
                    border: none;
                    border-bottom: 1px solid #af8647;
                    cursor: pointer;
                    letter-spacing: 1px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    option {
                        display: none;
                    }
                }
                .dropdown-select {
                    visibility: hidden;
                    text-align: start;
                    max-height: 0;
                    width: 100%;
                    transition:
                        opacity 0.3s ease,
                        visibility 0.3s ease,
                        max-height 0.3s ease;
                    position: absolute;
                    background-color: #af8647;
                    z-index: 1;
                    .option-select {
                        padding: 10px 20px;
                        opacity: 0;
                        cursor: pointer;
                        transition: all 0.2s;
                        &:hover {
                            background-color: white;
                            color: #af8647;
                        }
                    }
                }
                .open-select {
                    opacity: 1;
                    visibility: visible;
                    max-height: 500px;
                    .option-select {
                        opacity: 1;
                    }
                }
            }
        }
        .section-2-1 {
            display: flex;
            justify-content: space-between;
            .range {
                .range-price {
                    .title-range {
                        font-size: 1.1rem;
                        text-align: start;
                        padding: 20px 0;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .slider .thumb {
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        background-color: #af8647;
                        cursor: grab;
                        outline: none;
                    }
                    .track-0,
                    .track-2 {
                        background-color: grey;
                        height: 2px;
                    }
                    .track-1 {
                        background-color: #af8647;
                        height: 1px;
                    }
                    .thumb-0,
                    .thumb-1 {
                        top: -10px;
                    }
                    .text-range {
                        padding: 20px 0;
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
    .section-3-kld2112 {
        width: 100vw;
        height: 100vh;
        .section-3-1-image-kld2112 {
            position: absolute;
            display: flex;
            z-index: 1;
            width: 100vw;
            .section-3-1-color-kld2112 {
                width: 30%;
                height: 100vh;
                background-color: rgb(135 117 78);
            }
            img {
                animation: fadeInSlide 0.2s ease-in-out;

                width: 70%;
                height: 100vh;
                object-fit: cover;
            }
        }

        .introduce {
            height: 100vh;
            width: 1300px;
            margin: 0 auto;
            color: white;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .introduce-1 {
                width: 800px;
                color: black;
                padding: 77px 60px 77px 86px;
                background-color: rgba(246, 244, 239, 1);
                .title {
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 3rem;
                    padding: 20px 0;
                }
                .sub-title {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    padding: 20px 0;
                    color: #af8647;
                    font-size: 1.2rem;
                }
                .text {
                    padding: 20px 0;
                }
                .form-submit {
                    input {
                        padding: 10px 20px;
                        width: 300px;
                        background-color: unset;
                        border: 1px solid grey;
                    }
                    .btn-link {
                        width: 400px;
                        background-color: unset;
                        font-size: 1rem;
                        color: #eb9d29;
                        text-decoration: none;
                        border: 2px solid #eb9d29;
                        transition:
                            box-shadow 0.2s ease-in-out,
                            color 0.5s ease-in-out;
                        text-align: center;
                        padding: 15px;
                        cursor: pointer;
                        text-transform: uppercase;
                        font-weight: 600;
                        &:hover {
                            color: white;
                            box-shadow: inset 0 0 2em 2em #eb9d29;
                        }
                    }
                }
            }
        }
        // .title {
        //     text-align: center;
        //     font-size: 3rem;
        //     text-transform: uppercase;
        //     letter-spacing: 2px;
        // }
        // .sub-title {
        //     text-align: center;
        //     font-size: 2rem;
        //     letter-spacing: 1px;
        //     margin: 0 auto;
        //     padding: 20px 10px;
        // }
        // .sub-text {
        //     font-size: 1.5rem;
        //     letter-spacing: 1px;
        //     margin: 0 auto;
        //     padding: 20px 10px;
        // }
    }
    .section-4-kld2112 {
        width: 1300px;
        margin: 0 auto;

        .title-section {
            text-align: center;
            font-size: 2.5rem;
            padding: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title-section {
            text-align: center;
            font-size: 1.3rem;
            padding: 0 0 50px 0;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .slider-section-4 {
            padding-bottom: 100px;
            .section-4-1 {
                width: 45%;
                cursor: pointer;
                .image {
                    margin: 20px;
                    overflow: hidden;
                    border-radius: 15px;
                    img {
                        border-radius: 15px;
                        object-fit: cover;
                        height: 350px;
                        width: 100%;
                        border-radius: 15px;
                        border: none;
                        transition: all 0.2s;
                        &:hover {
                            overflow: hidden;
                            border-radius: 15px;
                            transform: scale(1.21);
                        }
                    }
                }
                .title {
                    font-size: 1.3rem;
                    letter-spacing: 2px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 20px;
                }
                .sub-title {
                    font-size: 14px;
                    font-weight: bold;
                    padding: 0 20px;
                }
            }

            .btn-prev-next {
                transition: all 0.2s;
                &:hover {
                    background-color: #af8647;
                    .fa-chevron-left,
                    .fa-chevron-right {
                        color: white !important;
                    }
                }
            }
        }
    }
    .section-5-kld2112 {
        --sectionBackground: linear-gradient(rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0.57)),
            url(https://res.cloudinary.com/dolydpat4/image/upload/v1734809442/element-bg2-1_exrewp.webp);
        background-image: none;
        transition: all 0.5s;
        clip-path: polygon(0px -2px, 100% -2px, 100% 102%, 0% 102%);
        .testimonials-section:before {
            display: block;
            content: "";
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: var(--sectionBackground) center no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            position: fixed;
            z-index: -1;
            pointer-events: none;
        }
        .testimonials-section {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 900px;
            height: 700px;
            margin: 0 auto;
            padding: 150px 0;
            gap: 30px;
            transition: max-height 0.5s;
            .title {
                font-size: 1.4rem;
                padding-bottom: 30px;
                letter-spacing: 3px;
                transition: all 0.5s;
            }
            .text {
                padding-bottom: 30px;
                font-size: 15px;
                line-height: 25px;
                max-height: 500px;
                transition: max-height 0.5s ease;
                transition: all;
            }
        }
    }
    .section-6-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 50px;
        .title {
            text-align: center;
            font-size: 2rem;
            padding: 0 50px 50px 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title {
            text-align: center;
            font-size: 1rem;
            letter-spacing: 1px;
            width: 1000px;
            margin: 0 auto;
        }
        .form-submit {
            display: flex;
            gap: 30px;
            padding: 50px 0;
            justify-content: center;
            input {
                padding: 10px 20px;
                width: 300px;
                background-color: unset;
                border: 1px solid grey;
            }
            .btn-link {
                width: 200px;
                background-color: unset;
                font-size: 1rem;
                color: white;
                text-decoration: none;
                border: 2px solid white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 15px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                &:hover {
                    color: #af8647;
                    box-shadow: inset 0 0 1.5em 1.5em #fff;
                }
            }
        }
    }
    .section-7-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 50px;
        .title {
            text-align: center;
            font-size: 2.5rem;
            padding: 0 50px 50px 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title {
            text-align: center;
            font-size: 1rem;
            letter-spacing: 1px;
            width: 1000px;
            margin: 0 auto;
            padding-bottom: 30px;
        }
        .section-7-1 {
            display: flex;
            .section-7-1-1 {
                padding: 30px 20px;
                text-align: center;
                .title-section-7-1-1 {
                    font-size: 2rem;
                    padding: 10px 0;
                }
                .text-section-7-1-1 {
                    font-size: 1rem;
                    padding: 10px 0;
                }
            }
        }
    }
    .section-8-kld2112 {
        width: 1300px;
        margin: 0 auto;
        color: white;
        padding: 50px 0;
        .title {
            text-align: center;
            font-size: 2.5rem;
            padding: 0 50px 50px 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        .sub-title {
            text-align: center;
            font-size: 1rem;
            letter-spacing: 1px;
            width: 1000px;
            margin: 0 auto;
            padding-bottom: 30px;
        }
        .accordion-flush {
            .accordion-item {
                border: none;
                color: white;
                padding: 10px 0;
                background-color: unset;
                .accordion-header {
                    .accordion-button {
                        border-radius: 15px;
                        &:after {
                            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
                            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e");
                        }
                        padding: 30px;
                        color: white;
                        font-size: 1.3rem;
                        background-color: rgba(246, 244, 239, 0.092);
                    }
                    .accordion-button:not(.collapsed) {
                        box-shadow: none;
                        border-radius: 15px 15px 0 0;
                    }
                }
                .accordion-collapse {
                    padding: 15px;
                    background-color: rgba(246, 244, 239, 0.092);
                    border-radius: 0 0 15px 15px;
                }
            }
        }
    }
    .section-9-kld2112 {
        width: 1330px;
        margin: 0 auto;
        color: white;
        .report {
            display: flex;
            flex-wrap: wrap;
            .report-detail {
                padding: 40px 20px;
                cursor: pointer;
                text-decoration: none;
                color: white;
                .image {
                    width: 100%;
                    height: 300px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 300px;
                        object-fit: cover;
                        transition: all 0.2s;
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
                .title-report {
                    font-size: 1.5rem;
                    padding: 20px 0;
                    letter-spacing: 1px;
                    font-weight: bold;
                }
                .sub-report {
                    padding: 10px 0px;
                    font-size: 14px;
                    letter-spacing: 1px;
                }
                .text-report {
                    font-size: 13px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
            }
        }
        .pagination-report {
            display: flex;
            gap: 20px;
            justify-content: center;
            padding: 20px;
            div {
                padding: 10px 20px;
                border-radius: 50%;
                cursor: pointer;
                border: none;
                transition: all 0.2s;
                &:hover {
                    background-color: #af8647;
                }
            }
            .active {
                background-color: #af8647;
            }
        }
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .first-layout-kld2112 {
        .section-1-kld2112 {
            width: 100%;
            height: 400px !important;
            img {
                height: 400px;
            }
            .introduce {
                height: 400px;
                padding: 60px 20px;
                .title {
                    width: 100%;
                    font-size: 1.7rem;
                }
                .sub-title {
                    width: 100%;
                    font-size: 13px;
                    letter-spacing: 1px;
                }
                .btn-intro {
                    .btn-ele {
                        width: 150px;
                        font-size: 13px;
                        border: 1px solid white;
                    }
                }
            }
        }
        .section-2-kld2112 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                padding: 20px 0;
                font-size: 1.5rem;
            }
            .form-group {
                flex-direction: column;
                gap: 30px;
            }
            .section-2-1 {
                flex-direction: column;
            }
        }
        .section-3-kld2112 {
            width: 100%;
            height: 100%;
            .section-3-1-image-kld2112 {
                height: 300px;
                position: relative;
                .section-3-1-color-kld2112 {
                    width: 0;
                }
                img {
                    width: 100%;
                    height: 300px;
                }
            }
            .introduce {
                width: 100%;
                height: max-content;
                position:unset;
                .introduce-1 {
                    padding: 50px 20px;
                    width: 100%;
                    .sub-title {
                        padding: 0;
                        font-size: 1rem;
                    }
                    .title {
                        font-size: 1.5rem;
                    }
                    .text {
                        font-size: 15px;
                    }
                }
            }
        }
        .section-4-kld2112 {
            width: 100%;
            .title-section {
                font-size: 1.5rem;
                padding: 50px 20px 20px 20px;
            }
            .sub-title-section {
                font-size: 1rem;
                padding: 10px 20px;
            }
            .slider-section-4 {
                padding-bottom: 100px;
                .btn-prev-next {
                    display: none;
                }
                .section-4-1 {
                    width: 100%;
                    cursor: pointer;

                    .image {
                        margin: 20px;
                        overflow: hidden;
                        border-radius: 15px;
                        img {
                            height: 300px;
                        }
                    }
                    .title {
                        text-align: center;
                        font-size: 1.5rem;
                        padding: 0 20px;
                    }
                    .sub-title {
                        text-align: center;
                        font-size: 1rem;
                        font-weight: normal;
                        padding: 10px 20px;
                    }
                }

                .btn-prev-next {
                    transition: all 0.2s;
                    &:hover {
                        background-color: #af8647;
                        .fa-chevron-left,
                        .fa-chevron-right {
                            color: white !important;
                        }
                    }
                }
            }
        }
        .section-5-kld2112 {
            width: 100%;
            .testimonials-section {
                width: 100%;
                height: 650px;
                flex-direction: column;
                .col-md-3 {
                    svg {
                        padding: 20px;
                        width: 150px;
                        height: 150px;
                    }
                }
                .col-md-9 {
                    width: 100%;
                    padding: 20px;
                }
                .title {
                    font-size: 1.3rem;
                }
                .text {
                    padding-bottom: 20px;
                    font-size: 13px;
                }
                .sub-title {
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
            }
        }
        .section-6-kld2112 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                padding: 20px 0;
                font-size: 1.5rem;
            }
            .sub-title {
                width: 100%;
                font-size: 15px;
            }
        }
        .section-7-kld2112 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                padding: 20px 0;
                font-size: 1.5rem;
            }
            .sub-title {
                width: 100%;
                padding: 20px;
                font-size: 15px;
            }
            .section-7-1 {
                flex-direction: column;
            }
        }
        .section-8-kld2112 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                padding: 20px 0;
                font-size: 1.5rem;
            }
            .sub-title {
                width: 100%;
                padding: 20px;
                font-size: 15px;
            }
            .accordion-flush .accordion-item .accordion-header .accordion-button {
                font-size: 1rem;
            }
            .accordion-body {
                font-size: 15px;
            }
        }
        .section-9-kld2112 {
            width: 100%;
        }
    }
}
