.homepage-kld2812 {
    font-family: "Roboto", serif;
    .section-1-kld2812 {
        width: 100vw;
        height: 100vh;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100vh;
            width: 100%;
            color: black;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            padding: 0 150px;
            .title {
                font-family: "Dancing Script", serif;
                width: 800px;
                text-align: center;
                font-size: 7rem;
                letter-spacing: 2px;
            }
            .sub-title {
                font-family: "Roboto", serif;
                width: 800px;
                text-transform: uppercase;
                text-align: center;
                font-size: 5rem;
                letter-spacing: 4px;
                font-weight: 400;
            }
            .text {
                font-family: "Roboto", serif;
                width: 800px;
                text-transform: uppercase;
                text-align: center;
                font-size: 1rem;
                letter-spacing: 1px;
                font-weight: bold;
            }
        }
    }
    .section-2-kld2812 {
        width: 1000px;
        margin: 0 auto;
        padding: 100px 0 50px 0;
        .section-2-1 {
            display: flex;
            flex-direction: column;
            gap: 50px;
            text-align: center;

            width: 100%;
            .title {
                font-family: "Dancing Script", serif;
                font-size: 3rem;
                font-weight: bold;
                letter-spacing: 1px;
            }
            .text {
                font-family: "Dancing Script", serif;
                font-size: 2rem;
                font-weight: bold;
                letter-spacing: 2px;
                .sub-text {
                    font-family: "Roboto", serif;
                    font-weight: normal;
                    font-size: 1rem;
                }
            }
            .images {
                width: 100%;
                display: flex;
                justify-content: space-between;
                img {
                    object-fit: contain;
                    height: 50px;
                    width: 100px;
                }
            }
        }
    }
    .section-3-kld2812 {
        width: 1000px;
        margin: 0 auto;
        padding: 100px 0;
        .section-3-1 {
            display: flex;
            flex-direction: column;
            gap: 50px;
            text-align: center;
            padding: 100px 0;
            width: 100%;
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 2px;
                font-style: italic;
            }
        }
        .section-3-2 {
            display: flex;

            .left {
                font-size: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            .right {
                display: flex;
                font-family: "Dancing Script", serif;
                flex-direction: row-reverse;
                align-items: center;
                font-size: 3rem;
                font-weight: bold;

                .text {
                    cursor: pointer;
                    position: relative;
                    text-align: end;
                    width: max-content;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: black;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        color: pink;
                        transition: all 0.2s ease;
                        &::after {
                            width: 0%;
                        }
                    }
                }
            }
        }
    }
    .section-4-kld2812 {
        width: 1400px;
        height: max-content;
        margin: 0 auto;
        display: flex;
        padding: 50px 0;
        .e1st {
            img {
                padding: 50px;
                width: 100%;

                object-fit: contain;
            }
        }
        .e2nd {
            img {
                padding: 20px;
                width: 100%;
            }
        }
    }
    .section-5-kld2812 {
        width: 1000px;
        margin: 0 auto;
        padding: 100px 0;
        .section-5-1 {
            display: flex;
            flex-direction: column;
            gap: 50px;
            text-align: center;
            padding: 100px 0;
            width: 100%;
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 2px;
                font-style: italic;
            }
        }
        .section-5-2 {
            display: flex;
            .left {
                font-size: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            .right {
                display: flex;
                font-family: "Dancing Script", serif;
                align-items: center;
                font-size: 3rem;
                font-weight: bold;

                .text {
                    cursor: pointer;
                    position: relative;
                    text-align: start;
                    width: max-content;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: black;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        transition: all 0.2s ease;
                        color: pink;

                        &::after {
                            width: 0%;
                        }
                    }
                }
            }
        }
    }
    .section-6-kld2812 {
        #horizontal {
            display: flex;
            overflow: hidden;
            .content {
                height: 100vh;
                width: 100vw;
                display: grid;
                padding: 0 50px;
                flex-shrink: 0;
                .section-6-1 {
                    display: flex;
                    gap: 50px;
                    padding: 0 20px;
                    img {
                        height: 100vh;
                        width: 600px;
                        object-fit: cover;
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .title {
                            color: white;
                            font-size: 3rem;
                            font-family: "Dancing Script", serif;
                            width: 700px;
                            padding: 20px 0;
                            letter-spacing: 2px;
                        }
                        .btn-right {
                            color: white;
                            cursor: pointer;
                            position: relative;
                            text-align: start;
                            width: max-content;
                            overflow: hidden;
                            padding: 10px 0;
                            font-size: 1.5rem;
                            letter-spacing: 1px;
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                width: 100%;
                                background-color: white;
                                transition: width 0.4s ease;
                            }

                            &:hover {
                                transition: all 0.2s ease;
                                color: pink;

                                &::after {
                                    width: 0%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .section-7-kld2812 {
        width: 1000px;
        margin: 0 auto;
        padding: 100px 0;
        color: white;
        .section-7-1 {
            display: flex;
            flex-direction: column;
            gap: 50px;
            text-align: center;
            padding: 100px 0;
            width: 100%;
            .title {
                font-size: 1.5rem;
                font-weight: bold;
                letter-spacing: 2px;
                font-style: italic;
            }
        }
        .section-7-2 {
            display: flex;
            .left {
                font-size: 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            .right {
                display: flex;
                font-family: "Dancing Script", serif;
                align-items: center;
                font-size: 3rem;
                font-weight: bold;

                .text {
                    cursor: pointer;
                    position: relative;
                    text-align: start;
                    width: max-content;
                    overflow: hidden;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        background-color: white;
                        transition: width 0.4s ease;
                    }

                    &:hover {
                        transition: all 0.2s ease;
                        color: pink;

                        &::after {
                            width: 0%;
                        }
                    }
                }
            }
        }
    }
    .section-8-kld2812 {
        width: 100%;
        display: flex;
        margin: 0 auto;
        .col-3 {
            display: flex;
            gap: 10px;
            flex-direction: column;
            img {
                padding: 0 10px;
                width: 100%;
                height: 600px;
                object-fit: contain;
            }
        }
        .col-3-3 {
            padding-top: 150px;
        }
        .col-3-2 {
            padding-top: 300px;
        }
    }
    .section-9-kld2812 {
        width: 1400px;
        margin: 0 auto;
        padding: 100px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
        position: relative; /* Đảm bảo phần tử cha có vị trí tương đối */

        .title {
            cursor: pointer;
            margin: 0 auto;
            width: max-content;
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
            letter-spacing: 2px;
            font-style: italic;
            position: relative; /* Vẫn ở trên ảnh */
            z-index: 2;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: white;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
        .box-text {
            padding: 400px 0;
            .sub-title {
                font-family: "Dancing Script", serif;
                font-size: 3rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
                text-align: center;
                color: white;
                position: relative;
                z-index: 2;
            }

            .text {
                text-align: center;
                color: white;
                font-size: 1rem;
                display: flex;
                flex-direction: column;
                gap: 20px;
                position: relative;
                z-index: 2;
            }
        }

        .img-left,
        .img-right {
            position: absolute; /* Đưa ảnh ra khỏi dòng chảy bình thường */
            top: 50%; /* Định vị tương đối với cha */
            transform: translateY(-50%); /* Căn giữa theo trục dọc */
            z-index: 1; /* Đảm bảo ảnh nằm dưới văn bản */
        }

        .img-left {
            left: 0%; /* Đưa ảnh sang bên phải */
            width: 300px;
            height: 400px;
            object-fit: cover;
        }

        .img-right {
            right: 0%; /* Đưa ảnh sang bên trái */
            width: 300px;
            height: 400px;
            object-fit: cover;
        }
    }
    .section-10-kld2812 {
        .content {
            height: 100vh;
            width: 100vw;
            display: grid;
            padding: 0 20px;
            flex-shrink: 0;
            .section-10-1 {
                display: flex;
                gap: 50px;
                padding: 0 20px;
                img {
                    height: 100vh;
                    width: 800px;
                    object-fit: cover;
                }
                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .title {
                        color: white;
                        font-size: 3rem;
                        font-family: "Dancing Script", serif;
                        width: 700px;
                        padding: 20px 0;
                        letter-spacing: 2px;
                    }
                    .btn-right {
                        color: white;
                        cursor: pointer;
                        position: relative;
                        text-align: start;
                        width: max-content;
                        overflow: hidden;
                        padding: 10px 0;
                        font-size: 1.5rem;
                        letter-spacing: 1px;
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 2px;
                            width: 100%;
                            background-color: white;
                            transition: width 0.4s ease;
                        }

                        &:hover {
                            transition: all 0.2s ease;
                            color: pink;

                            &::after {
                                width: 0%;
                            }
                        }
                    }
                }
            }
        }
    }
    .section-11-kld2812 {
        height: 100vh;
        width: 1000px;
        margin: 0 auto;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
        .title {
            color: black;
            font-size: 3rem;
            font-family: "Dancing Script", serif;
            width: 700px;
            padding: 20px 0;
            letter-spacing: 2px;
        }
        .box-sent {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            .text {
                font-size: 1.2rem;
                letter-spacing: 2px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .input-text {
                border: none;
                border-bottom: 1px solid grey;
                text-align: center;
                padding: 5px 10px;
                background-color: unset;
                &::placeholder {
                    text-transform: uppercase;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .btn-sent {
            cursor: pointer;
            position: relative;
            text-align: start;
            width: max-content;
            overflow: hidden;
            padding: 10px 0;
            font-size: 1.5rem;
            letter-spacing: 1px;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100%;
                background-color: black;
                transition: width 0.4s ease;
            }

            &:hover {
                transition: all 0.2s ease;
                color: pink;

                &::after {
                    width: 0%;
                }
            }
        }
    }

    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .homepage-kld2812 {
        .section-1-kld2812 {
            width: 100%;
            height: 500px;
            img {
                height: 500px;
            }
            .introduce {
                width: 100%;
                height: 500px;
                padding: 20px;
                .title {
                    width: 100%;
                    font-size: 3rem;
                    line-height: unset;
                }
                .text {
                    width: 100%;
                    font-size: 0.8rem;
                }
                .sub-title {
                    width: 100%;
                    text-align: center;
                    font-size: 1.5rem;
                }
            }
        }
        .section-2-kld2812 {
            width: 100%;
            padding: 50px 20px;
            .section-2-1 {
                width: 100%;
                .title {
                    font-size: 2rem;
                }
                .images {
                    img {
                        height: 50px;
                        object-fit: contain !important;
                    }
                }
            }
        }
        .section-3-kld2812 {
            width: 100%;
            padding: 50px 20px;
            .section-3-1 {
                width: 100%;
                padding: 20px 0;
            }
            .section-3-2 {
                padding:10px 0;
                .left {
                    .text {
                        font-size: 0.6rem;
                        text-align: center;
                    }
                }
                .right {
                    font-size: 1.5rem;
                    justify-content: center;
                    padding: 20px;
                }
            }
        }
        .section-4-kld2812 {
            width: 100%;
            height: max-content;
            padding: 30px 0;
            .e1st{
                img{
                    padding:20px;
                }
            }
        }
        .section-5-kld2812 {
            width: 100%;
            padding: 50px 20px;
            .section-5-1 {
                padding: 0 ;
                .title {
                    font-size: 1.5rem;
                }
            }
            .section-5-2 {
                flex-direction: column;
                align-items: center;
                .left {
                    order: 2;
                    .text {
                        text-align: center;
                        font-size: 0.8rem;
                    }
                }
                .right {
                    .text{
                        font-size: 2rem;
                    }
                    order: 1;
                    padding: 50px 0;
                    text-align: center;
                }
            }
        }
        .section-6-kld2812 {
            .content {
                width: 100% !important;
                padding: 0 10px !important;
                .section-6-1 {
                    align-items: center;
                    height: 100vh;
                    width: 100% !important;
                    gap:20px !important;
                    .left {
                        width: 200px !important;
                        height: 300px !important;
                    }
                    .right {
                        width: 100% !important;
                        .title {
                            width: 100% !important;
                            font-size: 1.6rem !important;
                        }
                        .btn-right{
                            font-size: 1rem !important;
                        }
                    }
                }
            }
        }
        .section-7-kld2812 {
            width: 100%;
            padding: 50px 20px;
            .section-7-1 {
                padding: 50px 0 0 0;
                .text {
                    font-size: 1rem;
                }
            }
            .section-7-2 {
                flex-direction: column;
                text-align: center;
                align-items: center;
                .right {
                    padding: 50px 0;
                }
            }
        }
        .section-8-kld2812 {
            width: 100%;
            .col-3 {
                img {
                    height: 150px;
                }
            }
            .col-3-2 {
                padding-top: 150px;
            }
            .col-3-3 {
                padding-top: 75px;
            }
        }
        .section-9-kld2812 {
            display: none;
        }
        .section-10-kld2812 {
            width: 100%;
            padding: 50px 20px;
            .content {
                width: 100%;
                .section-10-1 {
                    flex-direction: column;
                    width: 100%;
                    padding: 0;
                    .left {
                        height: 300px;
                        width: 100%;
                    }
                    .right {
                        width: 100%;
                        .title {
                            width: 100%;
                            font-size: 2rem;
                        }
                        .btn-right {
                            font-size: 1.3rem;
                        }
                    }
                }
            }
        }
        .section-11-kld2812 {
            width: 100%;
            padding: 20px;
            .title {
                width: 100%;
                font-size: 2rem;
            }
            .box-sent {
                .text {
                    font-size: 1rem;
                }
                .input-text {
                    font-size: 1rem;
                }
            }
            .btn-sent {
                font-size: 1.5rem;
            }
        }
    }
}
