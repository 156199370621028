.footer-homepage {
    border-top: 1px solid #97979747;
    padding: 30px 0;
    color:white;
    .content {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        
        img{
            // filter: drop-shadow(rgb(255, 255, 255) 10px 10px 1rem);
        }
        .info-footer{
            display:flex;
            flex-direction: column;
            gap:5px
        }
        .contact-footer{
            font-weight: bold;
            display:flex;
            flex-direction: column;
            gap:5px
        }
    }
}
@media (max-width: 950px) {
    .footer-homepage {
        hr{
            padding:0
        }
    }
}