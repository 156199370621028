.footer-kld1901 {
    font-family: "Montserrat", serif;
    .section-1-kld1901 {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        .e1st-kld1901 {
            img {
                width: 200px;
                height: 100px;
                object-fit: cover;
            }
            .text {
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1px;
                font-size: 1rem;
                padding-right:20px;
            }
        }
        .e2nd-kld1901 {
            display: flex;
            padding-top: 10px;
            gap: 20px;
            flex-direction: column;
            .title {
                font-size: 1.1rem;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
                
            }
            .list-nav-footer-kld1901 {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .nav-footer-kld1901 {
                    cursor: pointer;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    i {
                        color: #af8647;
                    }
                    &:hover {
                        color: #af8647;
                        transition: all 0.2s ease;
                    }
                }
            }
        }
    }
    .section-4-kld1901 {
        width: 1400px;
        margin: 0 auto;
    }

    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 2s ease;
    }

    .animate-section.animate {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .footer-kld1901 {
        .section-1-kld1901 {
            width: 100%;
            padding: 10px 20px;
            img {
                width: 150px;
            }
        }
    }
}
