.footer-kld2812 {
    background-color: #fff;
    padding: 50px 20px;
    .section-1-kld2812 {
        display: flex;
        justify-content: space-between;
        width: 1300px;
        margin: 0 auto;
        .left {
            .title {
                width: 90%;
                font-size: 1.5rem;
                letter-spacing: 2px;
                font-family: "Dancing Script", serif;
            }
            .sub-title {
                width: 90%;
                font-size: 3rem;
                letter-spacing: 2px;
                font-family: "Dancing Script", serif;
            }
            .text {
                width: 90%;
                font-size: 1.5rem;
                font-family: "Dancing Script", serif;
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .nav-item-link {
                display: flex;
                flex-wrap: wrap;
                .col-md-6 {
                    padding-bottom: 20px;
                    font-family: "Roboto", serif;
                    font-size: 1.2rem;
                    letter-spacing: 2px;
                }
            }
            .brand {
                font-family: "Dancing Script", serif;
                font-size: 3rem;
                letter-spacing: 2px;
            }
        }
    }
}
@media (max-width: 950px) {
    .footer-kld2812 {
        .section-1-kld2812 {
            width: 100%;
            flex-direction: column;
            .left {
                width: 100%;
                .title {
                    width: 100%;
                }
                .sub-title {
                    padding:20px 0;
                    width: 100%;
                    font-size: 2rem;
                }
            }
            .right {
                width: 100%;
                .nav-item-link{
                    padding:20px 0;
                    flex-direction: column;
                }
            }
        }
    }
}
