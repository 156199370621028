.third-layout-kld1901 {
    font-family: "Play", serif;
    .section-1-kld1901 {
        margin: 0 auto;
        display: flex;
        padding: 150px 0;
        width: 1400px;
        .left {
            .title {
                text-align: center;
                font-size: 2rem;
                color: #af8647;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-weight: bold;
            }
            .bxl {
                display: flex;
                gap: 20px;
                padding: 40px 0;
                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                    border-radius: 15px;
                }
                .cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;
                    .abv {
                        font-size: 1rem;
                        color: #af8647;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    .blw {
                        font-size: 2rem;
                        letter-spacing: 1px;
                        font-weight: bold;
                    }
                    .btn-blw {
                        button {
                            background: none;
                            border-radius: 15px;
                            padding: 10px 20px;
                            border: 1px solid #af8647;
                            transition: all 0.2s;
                            &:hover{
                                background-color: #af8647;
                                color:white;
                            }
                        }
                    }
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            gap: 40px;
            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                text-transform: uppercase;
                font-weight: bold;
                color: #4a4a4a;
                &::before,
                &::after {
                    content: "";
                    flex: 1;
                    height: 1px;
                    background-color: #4a4a4a;
                    margin: 0 10px;
                }
            }
            .bxs {
                width: calc(100% - 20px);
                margin: 0 auto;
                input {
                    width: 85%;
                    border-radius: 13px 0 0 13px;
                    padding: 10px;
                    border: 1px solid grey;
                }
                button {
                    i {
                        color: white;
                    }
                    width: 15%;
                    background-color: #af8647;
                    border-radius: 0 13px 13px 0;
                    border: 1px solid #af8647;
                    padding: 10px;
                }
            }
            .bxfr {
                width: calc(100% - 20px);
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .text {
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                    padding: 10px 0;
                    // border-bottom: 1px solid black;
                    transition: all 0.5s;
                    &:hover {
                        color: #af8647;
                    }
                }
            }
        }
    }
}
