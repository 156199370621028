.blog-website {
    color: white;

    .list-blog-website {
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
        padding: 150px 0 100px 0;
    
        .title {
            margin: 0 auto;
            text-align: center;
            padding: 20px 0;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .list-blog {
            margin: 0;

            .content {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding: 10px;
                a {
                    text-decoration: none;
                    color: white;
                }
                img {
                    width: 100%;
                    height: 200px;
                    border-radius: 15px;
                }
                .time {
                    color: grey;
                    font-size: 13px;
                    text-transform: uppercase;
                    padding:10px 0;
                }
                .name {
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
        }
        .type-website {
            max-width: 1200px;
            margin: 0 auto;
            justify-content: space-between;
            .title {
                margin: 0 auto;
                text-align: center;
                padding: 20px 0;
                font-size: 2.5rem;
                font-weight: bold;
            }
            .content {
                display: flex;
                justify-content: center;
                ul {
                    li {
                        cursor: pointer;
                        padding: 5px 0;
                        a {
                            text-decoration: none;
                            color: white;
                            &:hover {
                                color: grey;
                            }
                        }
                        .active {
                            color: #50adff;
                        }
                    }
                }
            }
        }
        .animate-kassdev-bottom-3s {
            opacity: 0;
            transform: translateY(100px);
            transition: all 3s ease;
        }
        .animate-kassdev-bottom-2s {
            opacity: 0;
            transform: translateY(100px);
            transition: all 2s ease;
        }
        .animate-kassdev-bottom-1s {
            opacity: 0;
            transform: translateY(100px);
            transition: all 1s ease;
        }
        .animate-kassdev-show {
            opacity: 1;
            transform: translateY(0);
            transform: translateX(0);
        }
    }
    
}
@media (max-width: 950px) {
    .blog-website {
        color: white;
        .list-blog-website {
            width: 100%;
            padding: 150px 10px 100px 10px;
            .list-blog {
                gap: 20px;
                .content {
                    img {
                        width: 100%;
                        height: 250px;
                        border-radius: 15px;
                    }
                }
            }
            .type-website .content {
                justify-content: normal;
                ul {
                    margin: 0;
                }
            }
        }
    }
}