.contact-kld1312 {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    .section-1 {
        padding: 104px 0 0 0;
        .banner {
            animation: fadeInSlide 0.5s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 500px;
            object-fit: cover;
            z-index: 1;
            top: 100px;
        }
        .text-banner {
            background-color: rgba(0, 0, 0, 0.517);
            height: 496px;
            justify-content: center;
            align-items: center;
            z-index: 2;
            position: relative;
            color: white;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 3rem;
                text-transform: uppercase;
                width: 60%;
                text-align: center;
                padding: 20px 0;
                font-weight: bold;
                letter-spacing: 2px;
            }
            .sub-title {
                font-size: 20px;
            }
        }
    }
    .section-2 {
        background-color: rgb(19 55 95);
        padding: 100px 0;
        .content {
            width: 1200px;
            margin: 0 auto;
            color: white;
            .box {
                display: flex;
                flex-direction: column;
                gap: 20px;
                img {
                    width: 50px;
                }
                .title {
                    font-weight: bold;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
                .sub-title {
                    letter-spacing: 1px;
                }
                a {
                    font-weight: bold;
                    text-decoration: none;
                    letter-spacing: 1px;
                    font-size: 1.2rem;
                    color: white;
                }
            }
        }
    }
    .section-3 {
        background-color: rgb(242, 242, 242);
        padding: 100px 0;
        margin: 0 auto;
        .title {
            font-size: 3rem;
            color: black;
            text-align: center;
            width: 1000px;
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: bold;
        }
        .sub-title {
            font-size: 1.1rem;
            color: grey;
            text-align: center;
            width: 1000px;
            margin: 0 auto;
            text-transform: uppercase;
            padding-bottom: 30px;
        }
        .form-contact {
            width: 600px;
            margin: 0 auto;
            justify-content: center;
            .content-contact {
                display: flex;
                flex-direction: column;
                padding: 10px 0;
                input,
                textarea {
                    padding: 15px;
                    border-radius: 15px;
                    border: 1px solid rgb(220, 220, 220);
                }
                .label-contact {
                    padding: 0 15px;
                }
            }
            .button-contact {
                margin-top: 20px;
                padding: 20px;
                width: 100%;
                color: white;
                font-weight: 600;
                text-transform: uppercase;
                border-radius: 15px;
                background-color: rgb(19 55 95);
                letter-spacing: 1px;
                border: none;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background-color: #50adff;
                }
            }
        }
    }
    .section-4 {
        width: 100%;
    }
    .animate-section {
        opacity: 0;
        transform: translateY(50px);
        transition: all 0.5s ease;
    }

    .animate-section.animate {
        opacity: 1; 
        transform: translateY(0);
    }
}
@media (max-width: 950px) {
    .contact-kld1312 {
        .section-1 {
            padding: 90px 0 0 0;
            .banner {
                height: 300px;
                object-fit: cover;
                top: 90px;
            }
            .text-banner {
                height: 300px;
                .title {
                    font-size: 1.5rem;
                    width: 80%;
                }
                .sub-title {
                    font-size: 16px;
                }
            }
        }
        .section-2{
            padding: 10px;
            width: 100%;
            .content{
                width: 100%;
                .box{
                    padding:30px 10px;
                    i{
                        font-size: 35px !important;
                    }
                }
            }
        }
        .section-3{
            padding:30px 10px;
            width: 100%;
            .title{
                width: 100%;
                font-size: 1.5rem ;
            }
            .sub-title{
                width: 100%;
                font-size: 1rem ;
            }
            .form-contact{
                width: 100%;
            }
        }
    }
}
